
    /**
    * 更新排期状态
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17245 {}
  /**
 * YResult :YResult
 */
export class IResid17245 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17245, IResid17245 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/schedules/state',
        data: data
      }) 
  export default http