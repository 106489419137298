import React, { Component, Suspense } from 'react'
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom'
// import ErrorComponent from '@components/Error'
import { isLogin } from '@services/userInfo.service'
import Loading, { loading } from '@components/Loading'
loading('')
// @ts-ignore
import styles from './main.scss'
interface IRouterItem {
  path: string
  component?: string
  children: any
}

interface IComponentProp extends RouteComponentProps<any> {
  sidebarOpen: boolean
  routeConf: any[]
}

@withRouter
class Main extends Component<IComponentProp, any> {
  constructor(props) {
    super(props)
  }

  public render() {
    // @ts-ignore
    const pathname = this.props.location.pathname
    loading('hide')
    return (
      <section
        style={{ height: '100%' }}
        className={pathname === '/retail/activeGoods' ? styles.activeGoodsBg : undefined}
      >
        <div className={`${styles.container}`} id="container">
          <Suspense fallback={<Loading />}>
            <Switch>
              {this.props.routeConf.length > 0 &&
                this.props.routeConf.map((menuItem: IRouterItem) => {
                  const route = (r: any) => {
                    const ComponentItem = r.component
                    // const redirect = window.location.origin + r.path
                    // console.log(redirect)
                    return !!r.needLogin && !isLogin ? (
                      <Redirect to={{ pathname: `/login` }} />
                    ) : (
                      <Route path={r.path} component={ComponentItem} key={r.path} exact />
                    )
                  }
                  return menuItem.component ? route(menuItem) : menuItem.children.map((r: IRouterItem) => route(r))
                })}
              {/* <Route component={ErrorComponent} /> */}
              <Route render={() => <Redirect to={this.props.routeConf[0] && this.props.routeConf[0].path} />} />
            </Switch>
          </Suspense>
        </div>
      </section>
    )
  }
}

export default withRouter(Main)
