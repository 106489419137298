
    /**
    * 统计
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14375 {}
  /**
 * YResult<LivePlanCountStatisticsDto> :YResult
 */
export class IResid14375 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * LivePlanCountStatisticsDto
   */
  data?: {
    /**
     * 待审批数量
     */
    toAuditCount?: {};
    /**
     * 审批拒绝数量
     */
    auditRejectCount?: {};
    /**
     * 待确认数量
     */
    toConfirmCount?: {};
    /**
     * 待直播数量
     */
    toLiveCount?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14375, IResid14375['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/live/plan/statisticsCount',
        params: data
      }) 
  export default http