
    /**
    * 获取地址列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13255 {}
  export class IResid13255 {
  code?: number;
  data?: {
    /**
     * 省份province_id名称
     */
    provinceCodeName: string;
    /**
     * 区id名称
     */
    districtCodeName: string;
    /**
     * 街道id名称
     */
    streetCodeName: string;
    /**
     * 市id名称
     */
    cityCodeName: string;
    expressAddress: {
      accountNo?: string;
      addressNo?: string;
      cityCode?: number;
      createTime?: null;
      creator?: string;
      detailAddress?: string;
      districtCode?: number;
      id?: number;
      isDefault?: number;
      isDeleted?: number;
      phone?: string;
      postCode?: string;
      provinceCode?: number;
      receiver?: string;
      streetCode?: number;
      updateTime?: string;
      updator?: null;
    };
  }[];
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13255, IResid13255['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/list',
        params: data
      }) 
  export default http