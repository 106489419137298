
    /**
    * 后台 按照商品名称和类目，品牌等 搜索分销商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemBeforePickQueryParam :ActivityItemBeforePickQueryParam
 */
export class IReqid14025 {
  categoryNo?: {};
  itemName?: {};
  brandNo?: {};
  activityNo?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<ItemDistributionActivityPageResp>> :YResult
 */
export class IResid14025 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      itemUniqueNo?: {};
      itemName?: {};
      mainImage?: {};
      brandName?: {};
      categoryNo?: {};
      categoryName?: {};
      supplyPriceMin?: {};
      supplyPriceMax?: {};
      skuSize?: {};
      sampleSize?: {};
      isOnly?: {};
      /**
       * PlatformPrice
       */
      platformInfoList?: {
        shopType?: {};
        scalePriceMin?: {};
        scalePriceMax?: {};
        commissionRateMin?: {};
        commissionRateMax?: {};
        minDailyPrice?: {};
        maxDailyPrice?: {};
        minSuggestPrice?: {};
        maxSuggestPrice?: {};
      }[];
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14025, IResid14025['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/listAll',
        data: data
      }) 
  export default http