
    /**
    * 前台用户获取验证码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid12870 {
  /**
   * 手机号
   */
  mobile: string | number;
}
  export class IResid12870 {
  code?: number;
  data?: string;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid12870, IResid12870['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/account/sms',
        params: data
      }) 
  export default http