
    /**
    * 后台 按照商品名称和类目搜索分销商品,选中商品列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemBeforeWithPickQueryParam :ActivityItemBeforeWithPickQueryParam
 */
export class IReqid14515 {
  activityNo?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<ItemDistributionActivityPageResp>> :YResult
 */
export class IResid14515 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      itemUniqueNo?: {};
      itemName?: {};
      mainImage?: {};
      brandName?: {};
      categoryNo?: {};
      categoryName?: {};
      supplyPriceMin?: {};
      supplyPriceMax?: {};
      skuSize?: {};
      sampleSize?: {};
      isOnly?: {};
      /**
       * PlatformPrice
       */
      platformInfoList?: {
        shopType?: {};
        scalePriceMin?: {};
        scalePriceMax?: {};
        commissionRateMin?: {};
        commissionRateMax?: {};
        minDailyPrice?: {};
        maxDailyPrice?: {};
        minSuggestPrice?: {};
        maxSuggestPrice?: {};
      }[];
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14515, IResid14515['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/listAllPicked',
        data: data
      }) 
  export default http