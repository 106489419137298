
    /**
    * 登出
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13915 {}
  /**
 * YResult :YResult
 */
export class IResid13915 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13915, IResid13915 > = (data?) =>  request({
        method: 'GET',
        url: '/account/loginOut',
        params: data
      }) 
  export default http