
    /**
    * 获取品牌供应商列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18054 {
  SORT_LIVE_COUNT: string | number;
  /**
   * 品牌名称
   */
  brandName: string | number;
  /**
   * 是否独家0-否1-是
   */
  soleType: string | number;
  /**
   * 主营类目
   */
  mainCategoryNo: string | number;
  /**
   * 直播场地
   */
  liveAddress: string | number;
  /**
   * 拍摄场地
   */
  materialShootingAddress: string | number;
  /**
   * 合作方式
   */
  supplyType: string | number;
  /**
   * 升序,常备货值standingStockValue,准备时间liveReadyDay,SPU数submitPassSpuNum,已排场数liveCount
   */
  asc: string | number;
  /**
   * 降序,常备货值standingStockValue,准备时间liveReadyDay,SPU数submitPassSpuNum,已排场数liveCount
   */
  desc: string | number;
  /**
   * 1,按月2按日
   */
  timeType: string | number;
  /**
   * 时间
   */
  time: string | number;
}
  /**
 * YResult<YListData<SupplierBrandDto>> :YResult
 */
export class IResid18054 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 品牌logo
       */
      brandLogo?: {};
      /**
       * 常备货值
       */
      goodsValue?: {};
      /**
       * spu数量
       */
      spuNum?: {};
      /**
       * 准备时间
       */
      readyTime?: {};
      /**
       * 品牌名称
       */
      brandName?: {};
      /**
       * 供应商名称
       */
      supplierName?: {};
      /**
       * 主营类目
       */
      categoryName?: {};
      /**
       * 已排场次
       */
      liveCount?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18054, IResid18054['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/get_supplier_brand_list',
        params: data
      }) 
  export default http