
    /**
    * 查询库存sku
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14315 {
  /**
   * 锁库存开始时间
   */
  zhiboDate: string | number;
}
  export class IResid14315 {
  code?: number;
  data?: {
    propertyList?: {
      propertyName: string;
      propertyNo: string;
      propertyValues: {
        propertyValue: string;
        propertyValueNo: string;
      }[];
    }[];
    sampleSkuList?: {
      propertyValueStr: string;
      sampleSkuNo: string;
      /**
       * 是否需要归还 是否需要归还 0,不需要，1需要
       */
      isNeedReturn: number;
    }[];
    /**
     * 是否需要归还0不需要，1需要
     */
    isNeedReturn: string;
  };
  msg?: string;
  success?: boolean;
}
  export default (itemId: any,  data?: IReqid14315): AxiosPromise<IResid14315> => request({
        method: 'GET',
        url: '/wms/api/getSkuStock/'+itemId,
        params: data
      })