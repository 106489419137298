
    /**
    * 后台 置顶商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemStickParam :ActivityItemStickParam
 */
export class IReqid14355 {
  selectedItemId?: {};
  activityNo?: {};
}
  /**
 * YResult :YResult
 */
export class IResid14355 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14355, IResid14355 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/stick',
        data: data
      }) 
  export default http