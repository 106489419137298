import React, { ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
// @ts-ignore
import * as styles from './index.scss'
import { Input, Form, Button, message } from 'antd'
import API from '@frontApi/index'
import logo from './images/login_logo.png'
import loginLeftBg from './images/login_left_bg.png'
import rules from '@utils/rules'

interface IProps {}

type loginFormKey = 'username' | 'password' | 'usernameError' | 'passwordError'

interface ILoginFormParams {
  username?: string
  password?: string
  usernameError?: string
  passwordError?: string
  countdown?: number
  phone?: string
  phoneError?: string
  vertify?: string
  vertifyError?: string
  anchorName?: string
  anchorNameError?: string
}

interface IState {
  userInfo: ILoginFormParams
  vertifyInfo: ILoginFormParams
}

class Login extends React.Component<IProps & RouteComponentProps, IState> {
  state: IState = {
    userInfo: {
      username: '',
      usernameError: '',
      password: '',
      passwordError: '',
    },
    vertifyInfo: {
      anchorName: '',
      anchorNameError: '',
      phone: '',
      phoneError: '',
      vertify: '',
      vertifyError: '',
      countdown: 0,
    },
  }

  constructor(props: any) {
    super(props)
    this.vertifyLogin = this.vertifyLogin.bind(this)
    this.getVetrify = this.getVetrify.bind(this)
    this.userLoginFormHandle = this.userLoginFormHandle.bind(this)
  }

  // 验证码登录
  public async vertifyLogin() {
    const state = this.state
    if (!state.vertifyInfo.anchorName) {
      this.state.vertifyInfo['anchorNameError'] = '请输入您的昵称'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['phoneError'] = ''
      this.setState(state)
    }
    if (!state.vertifyInfo.phone) {
      this.state.vertifyInfo['phoneError'] = '请输入您的手机号'
      this.setState(state)
      return
    } else if (state.vertifyInfo.phone.length < 11) {
      this.state.vertifyInfo['phoneError'] = '请输入11位手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['phoneError'] = ''
      this.setState(state)
    }
    if (!state.vertifyInfo.vertify) {
      this.state.vertifyInfo['vertifyError'] = '请输入验证码'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['vertifyError'] = ''
      this.setState(state)
    }
    if (!state.userInfo.password) {
      this.state.userInfo['passwordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.userInfo.password)) {
      this.state.userInfo['passwordError'] = '密码必须字母数字组合'
      return
    } else {
      this.state.userInfo['passwordError'] = ''
      this.setState(state)
    }
    API.id16995({
      mobileNumber: this.state.vertifyInfo.phone,
      password: this.state.vertifyInfo.password,
      anchorName: this.state.vertifyInfo.anchorName,
      verificationCode: this.state.vertifyInfo.vertify,
    }).then(res => {
      message.success('操作成功！')
      setTimeout(() => {
        this.props.history.push(`/login?redirect=${window.location.origin}/retail/personalCenter?isEdit=true`)
      }, 1000)
    })
  }

  public userLoginFormHandle(event: ChangeEvent) {
    const element = event.target as HTMLInputElement
    const { name, value } = element
    const state = this.state
    state.userInfo[name as loginFormKey] = value
    state.vertifyInfo[name as loginFormKey] = value
    this.setState(state)
  }

  public getVetrify() {
    const state = this.state
    if (!state.vertifyInfo.phone) {
      this.state.vertifyInfo['phoneError'] = '请输入您的手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['phoneError'] = ''
      this.setState(state)
    }

    API.id16990({ mobile: state.vertifyInfo.phone }).then(res => {
      this.state.vertifyInfo['countdown'] = 60
      const countdown = setInterval(() => {
        this.state.vertifyInfo['countdown']--
        this.setState(state)
        if (this.state.vertifyInfo['countdown'] === 0) {
          clearInterval(countdown)
          this.state.vertifyInfo['countdown'] = 0
        }
      }, 1000)
      this.setState(state)
    })
  }

  public render() {
    return (
      <div className={styles.wrap}>
        <div className={styles.box}>
          <div>
            <div className={styles.loginHeader}>
              <a href="/">
                <img width="150" height="40" src={logo} alt="" />
              </a>
              <a href="/">返回首页</a>
            </div>
            <div className={styles.loginBox}>
              <div className={styles.loginBoxLeft}>
                <img width="376" height="365" src={loginLeftBg} alt="" />
              </div>
              <div className={styles.loginBoxRight}>
                <h3>
                  <i></i>注册
                </h3>
                <a className={styles.logintoRegister} href="/login">
                  登录
                </a>
                <div className={styles.loginContent}>
                  <Form>
                    <Form.Item>
                      <div className={styles.phoneLabel}>
                        <i></i>昵称
                      </div>
                      <Input
                        name="anchorName"
                        maxLength={11}
                        type="text"
                        placeholder="请输入您的昵称"
                        autoComplete="off"
                        onChange={this.userLoginFormHandle}
                      />
                      <p>
                        {this.state.vertifyInfo.anchorNameError && (
                          <>
                            <i></i>
                            {this.state.vertifyInfo.anchorNameError}
                          </>
                        )}
                      </p>
                    </Form.Item>
                    <Form.Item>
                      <div className={styles.phoneLabel}>
                        <i></i>手机号
                      </div>
                      <Input
                        name="phone"
                        maxLength={11}
                        type="text"
                        placeholder="请输入您的手机号"
                        autoComplete="off"
                        onChange={this.userLoginFormHandle}
                      />
                      <p>
                        {this.state.vertifyInfo.phoneError && (
                          <>
                            <i></i>
                            {this.state.vertifyInfo.phoneError}
                          </>
                        )}
                      </p>
                    </Form.Item>

                    <Form.Item>
                      <div className={styles.pwdLabel}>
                        <i></i>验证码
                      </div>
                      <Input
                        name="vertify"
                        maxLength={6}
                        type="text"
                        placeholder="请输入验证码"
                        autoComplete="off"
                        onChange={this.userLoginFormHandle}
                      />
                      <div className={styles.captcha}>
                        {!this.state.vertifyInfo.countdown ? (
                          <Button type="link" onClick={this.getVetrify}>
                            获取验证码
                          </Button>
                        ) : (
                          <Button type="link">{`${this.state.vertifyInfo.countdown}s`}</Button>
                        )}
                      </div>
                      <p>
                        {this.state.vertifyInfo.vertifyError && (
                          <>
                            <i></i>
                            {this.state.vertifyInfo.vertifyError}
                          </>
                        )}
                      </p>
                    </Form.Item>
                    <Form.Item>
                      <div className={styles.pwdLabel}>
                        <i></i>密码
                      </div>
                      <Input
                        name="password"
                        maxLength={12}
                        type="password"
                        placeholder="请输入密码"
                        autoComplete="off"
                        onChange={this.userLoginFormHandle}
                      />
                      <p>
                        {this.state.userInfo.passwordError && (
                          <>
                            <i></i>
                            {this.state.userInfo.passwordError}
                          </>
                        )}
                      </p>
                    </Form.Item>
                    <div className={styles.loginButton} onClick={this.vertifyLogin}>
                      注 册
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <p>浙ICP备12034747号-4 © 2020 YAOWANGXINGXUAN. All rigths reserved 2018</p>
        </div>
      </div>
    )
  }
}

export default Login
