
    /**
    * 后台分销商品列表更新或者上传卖点文件
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SellPointAttachmentUpdateParam :SellPointAttachmentUpdateParam
 */
export class IReqid17404 {
  /**
   * 外部商品id
   */
  itemUniqueNo?: {};
  /**
   * 附件地址
   */
  url?: {};
  /**
   * 动作类型，是更新还是上传更新1更新2上传
   */
  actionType?: {};
  operator?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17404 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17404, IResid17404 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/item/distribution/be/v2/attachment',
        data: data
      }) 
  export default http