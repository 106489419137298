
    /**
    * 活动列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17421 {}
  /**
 * YResult :YResult
 */
export class IResid17421 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17421, IResid17421 > = (data?) =>  request({
        method: 'GET',
        url: '/activityItem/list',
        params: data
      }) 
  export default http