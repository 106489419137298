import React from 'react'
const Retail = props => {
  const { loading = true } = props
  return (
    <div className="base-loading-layer">
      <div className="loader-outer " style={{ display: loading ? 'block' : 'none' }}>
        <div className="loader-inner line-scale-pulse-out-rapid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Retail
// loading加载
export function loading(action) {
  if (action === 'hide') {
    document.getElementById('loading').style.display = 'none'
  } else {
    document.getElementById('loading').style.display = 'block'
  }
}
