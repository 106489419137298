
    /**
    * 获取主播pid信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16965 {}
  /**
 * YResult<AnchorPidCountDto> :YResult
 */
export class IResid16965 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * AnchorPidCountDto
   */
  data?: {
    userNo?: {};
    /**
     * PidCount
     */
    pidCountList?: {
      platform?: {};
      count?: {};
    }[];
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16965, IResid16965['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/anchor/get_anchor_pid_info',
        params: data
      }) 
  export default http