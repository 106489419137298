
    /**
    * 删除活动商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemDelParam :ActivityItemDelParam
 */
export class IReqid14350 {
  activityNo?: {};
  selectedItemId?: {};
}
  /**
 * YResult :YResult
 */
export class IResid14350 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14350, IResid14350 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/del',
        data: data
      }) 
  export default http