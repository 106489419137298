
    /**
    * 新增/编辑排期计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SchedulingPlanSaveParam :SchedulingPlanSaveParam
 */
export class IReqid17341 {
  /**
   * 计划编号
   */
  uniqueNo?: {};
  /**
   * 直播间编号，为0时表示其他
   */
  liveRoomNo?: {};
  /**
   * 直播间备注
   */
  liveRoomRemark?: {};
  /**
   * 品牌 ,PlanBrandGmvSaveParam
   */
  planBrandGmvSaveParams?: {
    /**
     * 品牌编号，为0时表示其他
     */
    brandNo?: {};
    /**
     * 实际Gmv
     */
    actualMoney?: {};
    /**
     * 品牌备注
     */
    brandRemark?: {};
  }[];
  /**
   * 主播编号，为0时表示其他
   */
  anchorNo?: {};
  /**
   * 主播备注
   */
  anchorRemark?: {};
  /**
   * 直播间使用开始时间
   */
  beginTime?: {};
  /**
   * 直播间使用结束时间
   */
  endTime?: {};
  /**
   * 目标流水
   */
  goalStatement?: {};
  /**
   * 计划投放金额
   */
  planMoney?: {};
  /**
   * 直播备注
   */
  remark?: {};
  /**
   * 直播主題
   */
  title?: {};
  /**
   * bd组
   */
  bdGroupCode?: {};
  /**
   * 分销人员
   */
  bdCode?: {};
  /**
   * 0-待直播，1-已直播2-已取消
   */
  status?: {};
  /**
   * 直播平台
   */
  platform?: {};
  /**
   * 合作方式：多个合作方式用";"隔开
   */
  cooperationTypes?: {};
  /**
   * 1-直播排期添加2-分销排期添加
   */
  source?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17341 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17341, IResid17341 > = (data?) =>  request({
        method: 'POST',
        url: '/schedulingPlan/save_scheduling_plan',
        data: data
      }) 
  export default http