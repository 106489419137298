
    /**
    * 设置默认地址
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13260 {
  /**
   * 地址编号
   */
  addressNo?: string;
  /**
   * 是否默认地址:0默认1不默认
   */
  isDefault?: number;
}
  export class IResid13260 {
  code?: number;
  data?: null;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13260, IResid13260['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/expressAddress/isDefault',
        data: data
      }) 
  export default http