
    /**
    * 前台样品管理页面修改收货信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * LivePlanAddressModifyParam :LivePlanAddressModifyParam
 */
export class IReqid18638 {
  livePlanNo?: {};
  addressNo?: {};
}
  /**
 * YResult :YResult
 */
export class IResid18638 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18638, IResid18638 > = (data?) =>  request({
        method: 'POST',
        url: '/sampleManage/modifyLivePlanAddress',
        data: data
      }) 
  export default http