
    /**
    * 后台样品间管理列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17415 {
  sampleRoomNo?: string | number;
  /**
   * 样品间名称
   */
  sampleName?: string | number;
  /**
   * 负责人姓名
   */
  realName?: string | number;
  /**
   * 负责人联系方式
   */
  mobileNumber?: string | number;
  sampleAddress?: string | number;
  /**
   * 0正常
   */
  status?: string | number;
  /**
   * 分页
   */
  index?: string | number;
  size?: string | number;
}
  /**
 * YResult<YListData<SampleRoomDTO>> :YResult
 */
export class IResid17415 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      id?: {};
      /**
       * 样品间名称
       */
      sampleName?: {};
      /**
       * 样品间地址
       */
      sampleAddress?: {};
      /**
       * 负责人姓名
       */
      realName?: {};
      /**
       * 负责人联系方式
       */
      mobileNumber?: {};
      /**
       * 样品间编号
       */
      sampleRoomNo?: {};
      /**
       * 0正常1不正常
       */
      status?: {};
      isDeleted?: {};
      createTime?: {};
      updateTime?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17415, IResid17415['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/sampleRoom/listSampleRoom',
        params: data
      }) 
  export default http