
    /**
    * 编辑活动
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivitySaveParam :ActivitySaveParam
 */
export class IReqid13295 {
  id?: {};
  activityNo?: {};
  activityName: {};
  /**
   * String
   */
  bannerImage: {}[];
  shelfTime: {};
  offshelfTime: {};
  creator: {};
  isDeleted?: {};
  sortNum?: {};
  updator?: {};
  updateTime?: {};
  status?: {};
  createTime?: {};
}
  /**
 * YResult :YResult
 */
export class IResid13295 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13295, IResid13295 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/update',
        data: data
      }) 
  export default http