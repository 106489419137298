
    /**
    * 分页查询直播间
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17340 {
  /**
   * 直播间编号
   */
  roomNo?: string | number;
  /**
   * 直播间名称
   */
  roomName?: string | number;
  /**
   * 直播间类型1品牌馆2主播专用
   */
  roomType?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<LiveRoomDto>> :YResult
 */
export class IResid17340 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 直播间唯一标识
       */
      uniqueNo?: {};
      /**
       * 直播间编号
       */
      roomNo?: {};
      /**
       * 直播间名称
       */
      roomName?: {};
      /**
       * 楼宇
       */
      building?: {};
      /**
       * 楼层
       */
      storey?: {};
      /**
       * 1品牌馆2主播专用,0其他
       */
      roomType?: {};
      /**
       * 直播间大小
       */
      roomSize?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 直播间图片，多个图片用;隔开 ,String
       */
      image?: {}[];
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17340, IResid17340['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/liveRoom/page_list_live_room',
        params: data
      }) 
  export default http