
    /**
    * 区域查询
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13015 {
  /**
   * (Integer)
   */
  code?: string | number;
  /**
   * (Integer)
   */
  type?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid13015 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13015, IResid13015 > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/listArea',
        params: data
      }) 
  export default http