
    /**
    * 修改地址
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ExpressAddressParam :ExpressAddressParam
 */
export class IReqid13025 {
  /**
   * 省份province_id
   */
  provinceCode?: {};
  /**
   * 市id
   */
  cityCode?: {};
  /**
   * 区id
   */
  districtCode?: {};
  /**
   * 街道id
   */
  streetCode?: {};
  /**
   * 联系地址
   */
  detailAddress?: {};
  /**
   * 邮政编码
   */
  postCode?: {};
  /**
   * 收货人
   */
  receiver?: {};
  /**
   * 手机号码
   */
  phone?: {};
  /**
   * 是否默认地址
   */
  isDefault?: {};
  /**
   * 供应商品编号
   */
  addressNo?: {};
}
  /**
 * YResult :YResult
 */
export class IResid13025 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13025, IResid13025 > = (data?) =>  request({
        method: 'POST',
        url: '/expressAddress/update',
        data: data
      }) 
  export default http