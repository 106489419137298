/*
// 设置 rem 函数
function setRem() {
  //  PC端
  console.log('非移动设备')
  // 基准大小
  baseSize = 100;
  let basePc = baseSize / 1920; // 表示1920的设计图,使用100PX的默认值
  let vW = window.innerWidth; // 当前窗口的宽度
  let vH = window.innerHeight; // 当前窗口的高度
  // 非正常屏幕下的尺寸换算
  let dueH = vW * 1080 / 1920
  if (vH < dueH) { // 当前屏幕高度小于应有的屏幕高度，就需要根据当前屏幕高度重新计算屏幕宽度
    vW = vH * 1920 / 1080
  }
  console.log(vW, 'vW')
  vW = vW >= 1920 ? 1920 : vW
  vW = vW <= 1320 ? 1320 : vW
  let rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
  document.documentElement.style.fontSize = rem + "px";
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
if (window.localStorage.getItem('YW_RETAIL_PLATFORM') === 'retail') {
  window.onresize = function () {
    setRem()
  }
} else {
  window.onresize = null
  document.documentElement.style.fontSize = 100 + "px";
}*/

if (window.localStorage.getItem('YW_RETAIL_PLATFORM') === 'retail') {
  function setRem() {
    let vw = document.documentElement.clientWidth;
    if (vw <= 1080) {
      document.documentElement.style.fontSize = 85 + "px";
    } else {
      document.documentElement.style.fontSize = 100 + "px";
    }
  }
  window.onresize = setRem;
  setRem();

  let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  if (isMac) {
    document.documentElement.style.fontFamily = "PingFang SC Regular";
    document.getElementsByTagName('body')[0].style.fontFamily = "PingFang SC Regular";
  }
} else {
  document.documentElement.style.fontSize = 100 + "px";
}