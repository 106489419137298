
    /**
    * 分页查询用户列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13310 {
  queryParam?: string | number;
  /**
   * 账号
   */
  account?: string | number;
  /**
   * 用户手机号
   */
  mobileNumber?: string | number;
  /**
   * 主播昵称
   */
  anchorName?: string | number;
  /**
   * 主播类型
   */
  anchorType?: string | number;
  /**
   * 账号状态0禁用1启用
   */
  accountStatus?: string | number;
  /**
   * 绑定状态0未绑定1已绑定
   */
  bindStatus?: string | number;
  /**
   * 创建时间
   */
  createdAt?: string | number;
  createdTimeFrom?: string | number;
  createdTimeEnd?: string | number;
  /**
   * 更新时间
   */
  updatedAt?: string | number;
  updateTimeFrom?: string | number;
  updateTimeEnd?: string | number;
  /**
   * 是否删除，0.正常1.已删除
   */
  isDeleted?: string | number;
  /**
   * 绑定主播账号
   */
  bindAccount?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<AccountInternalDto>> :YResult
 */
export class IResid13310 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 账号
       */
      account?: {};
      /**
       * 用户手机号
       */
      mobileNumber?: {};
      /**
       * 主播昵称
       */
      anchorName?: {};
      /**
       * 主播头像
       */
      anchorImage?: {};
      /**
       * 主播类型
       */
      anchorType?: {};
      /**
       * 账号状态0禁用1启用
       */
      accountStatus?: {};
      /**
       * 绑定状态0未绑定1已绑定
       */
      bindStatus?: {};
      token?: {};
      /**
       * 创建时间
       */
      createdAt?: {};
      /**
       * 更新时间
       */
      updatedAt?: {};
      /**
       * 是否删除，0.正常1.已删除
       */
      isDeleted?: {};
      /**
       * 绑定主播账号
       */
      bindAccount?: {};
      /**
       * 绑定主播名称
       */
      bindName?: {};
      /**
       * 是否允许借样1-是允许，0-不允许
       */
      enableGetSample?: {};
      /**
       * 账号类型0子账号1主账号
       */
      accountType?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13310, IResid13310['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/account/selectPageList',
        params: data
      }) 
  export default http