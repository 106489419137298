
    /**
    * 校验验证码是否正确
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17420 {
  /**
   * (String)
   */
  mobile: string;
  /**
   * (String)
   */
  captcha: string;
}
  /**
 * YResult :YResult
 */
export class IResid17420 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17420, IResid17420 > = (data?) =>  request({
        method: 'POST',
        url: '/account/checkMtCode',
        data: data
      }) 
  export default http