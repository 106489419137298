
    /**
    * 新增/编辑直播间
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * LiveRoomSaveParam :LiveRoomSaveParam
 */
export class IReqid17339 {
  /**
   * 直播间唯一标识
   */
  uniqueNo?: {};
  /**
   * 直播间编号
   */
  roomNo: {};
  /**
   * 直播间名称
   */
  roomName: {};
  /**
   * 楼宇
   */
  building: {};
  /**
   * 楼层
   */
  storey: {};
  /**
   * 1品牌馆2主播专用,0其他
   */
  roomType: {};
  /**
   * 直播间大小
   */
  roomSize: {};
  /**
   * 品牌编号
   */
  brandNo?: {};
  /**
   * 直播间图片 ,String
   */
  image?: {}[];
}
  /**
 * YResult :YResult
 */
export class IResid17339 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17339, IResid17339 > = (data?) =>  request({
        method: 'POST',
        url: '/liveRoom/save_live_room',
        data: data
      }) 
  export default http