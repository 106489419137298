
    /**
    * 直播计划中取消商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18662 {}
  /**
 * YResult :YResult
 */
export class IResid18662 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18662, IResid18662 > = (data?) =>  request({
        method: 'POST',
        url: '/front/live/plan/cancelSampleItem',
        data: data
      }) 
  export default http