
    /**
    * 分销结算导出
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17701 {
  /**
   * 多个主播账号用逗号分割
   */
  anchorNo?: string | number;
  /**
   * 直播时间
   */
  liveStartDate?: string | number;
  liveEndDate?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid17701 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17701, IResid17701 > = (data?) =>  request({
        method: 'GET',
        url: '/distributionBalance/export',
        params: data
      }) 
  export default http