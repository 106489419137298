
    /**
    * 修改样品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SampleSettingParam :SampleSettingParam
 */
export class IReqid13955 {
  id?: {};
  /**
   * 直播前x条到样
   */
  beforeZhiboOut: {};
  /**
   * 直播后x天还样
   */
  afterZhiboOut: {};
  /**
   * 发货天数
   */
  deliveryDaysOut: {};
  /**
   * 归还天数
   */
  returnDaysOut: {};
  beforeZhiboIn: {};
  /**
   * 直播后x天还样
   */
  afterZhiboIn: {};
  /**
   * 发货天数
   */
  deliveryDaysIn: {};
  /**
   * 归还天数
   */
  returnDaysIn: {};
  /**
   * 是否需要审核0不需要1需要
   */
  needAuditIn?: {};
}
  /**
 * YResult :YResult
 */
export class IResid13955 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13955, IResid13955 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/sampleManage/setting',
        data: data
      }) 
  export default http