
    /**
    * 后台 添加活动商品前 根据 名称 和 类目 查询活动商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemQueryParam :ActivityItemQueryParam
 */
export class IReqid14360 {
  categoryNo?: {};
  activityNo?: {};
  itemName?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<ItemDistributionActivityViewDTO>> :YResult
 */
export class IResid14360 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      itemName?: {};
      categoryName?: {};
      itemNo?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14360, IResid14360['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/list',
        data: data
      }) 
  export default http