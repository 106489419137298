
    /**
    * 获取自有店铺类型
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17812 {}
  /**
 * YResult<YListData<OwnShop>> :YResult
 */
export class IResid17812 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      shopId?: {};
      shopName?: {};
      platformRate?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17812, IResid17812['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/importOrder/get_own_shop',
        params: data
      }) 
  export default http