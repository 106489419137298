
    /**
    * 计划审核
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * LivePlanAuditParam :LivePlanAuditParam
 */
export class IReqid14335 {
  /**
   * 计划编号
   */
  livePlanNo?: {};
  /**
   * 审核备注
   */
  auditRemark?: {};
  /**
   * 更新时间（校验用）
   */
  updateTime?: {};
  /**
   * 商品列表 ,SampleItem
   */
  sampleItemList?: {
    /**
     * 商品编号
     */
    sampleItemNo?: {};
    /**
     * 审核备注
     */
    auditRemark?: {};
    /**
     * 拒绝原因类型：0-通过2-成品库存不足2-样品库存不足3-其他
     */
    rejectReasonType?: {};
    /**
     * 拒绝原因
     */
    rejectReason?: {};
    /**
     * 样品Sku列表 ,SampleSku
     */
    sampleSkuList?: {
      /**
       * Sku编号
       */
      sampleSkuNo?: {};
      /**
       * 是否没有样品：0-否1-是
       */
      isNoSample?: {};
    }[];
  }[];
}
  /**
 * YResult :YResult
 */
export class IResid14335 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14335, IResid14335 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/live/plan/auditPlan',
        data: data
      }) 
  export default http