
    /**
    * 后台所有的一级类目
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17410 {}
  /**
 * YResult<YListData<CategoryStatisticsTreeNodeDto>> :YResult
 */
export class IResid17410 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      categoryNo?: {};
      categoryName?: {};
      parentNo?: {};
      hasChild?: {};
      isLeaf?: {};
      categoryNoPath?: {};
      total?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17410, IResid17410['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/item/firstLevel/category',
        params: data
      }) 
  export default http