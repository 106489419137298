
    /**
    * 分页查询订单任务
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18152 {
  /**
   * 订单类型1-cps2-一件代发3-自有店铺
   */
  orderType?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<ImportOrderTaskDto>> :YResult
 */
export class IResid18152 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 任务编号
       */
      uniqueNo?: {};
      /**
       * 文件名
       */
      fileName?: {};
      /**
       * 订单类型1-cps2-一件代发3-自有店铺
       */
      orderType?: {};
      /**
       * 导入状态0-未开始1-正在导入2-导入成功3-导入失败
       */
      status?: {};
      /**
       * 导入进度0-100
       */
      importProgress?: {};
      /**
       * 失败下载地址
       */
      errorUrl?: {};
      /**
       * 创建时间
       */
      createdAt?: {};
      /**
       * 开始导入时间
       */
      startTime?: {};
      /**
       * 结束导入时间
       */
      endTime?: {};
      /**
       * 创建人
       */
      creator?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18152, IResid18152['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/importOrderTask/page_list',
        params: data
      }) 
  export default http