
    /**
    * 超时未归还样品列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14190 {
  /**
   * 主播accountNo
   */
  accountNo?: string | number;
  /**
   * 是否允许借样1-是允许，0-不允许
   */
  enableGetSample?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<SampleReturnTimeoutDTO>> :YResult
 */
export class IResid14190 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * account关联字段
       */
      accountId?: {};
      accountMobile?: {};
      accountAnchorName?: {};
      accountNo?: {};
      /**
       * 借样状态
       */
      enableGetSample?: {};
      /**
       * 样品超时未归还
       */
      id?: {};
      totalSample?: {};
      timeoutNotReturn?: {};
      timeoutReturn?: {};
      ontimeReturn?: {};
      notReturn?: {};
      notReturnAmount?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14190, IResid14190['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/sampleReturnTimeout/list',
        params: data
      }) 
  export default http