
    /**
    * 批量查询代播单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * List<String>
 */
export type IReqid14125 = {}[];
  /**
 * YResult :YResult
 */
export class IResid14125 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14125, IResid14125 > = (data?) =>  request({
        method: 'POST',
        url: '/api/WaitingLiveItem/delWaitingLiveItem',
        data: data
      }) 
  export default http