
    /**
    * 搜索记录列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14205 {
  keyWords?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<SearchRecordCountDTO>> :YResult
 */
export class IResid14205 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 搜索字段
       */
      keyWords?: {};
      /**
       * 搜索次数
       */
      searchNum?: {};
      isDeleted?: {};
      /**
       * 最近一次的搜索时间
       */
      lastSearchTime?: {};
      createTime?: {};
      updateTime?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14205, IResid14205['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/searchRecord/listSearchRecordCount',
        params: data
      }) 
  export default http