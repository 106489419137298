import React, { Component, Suspense } from 'react'
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom'
import Loading from '@components/Loading'

import ErrorComponent from '@components/Error'
import Crumbs from './Crumbs'

interface IRouterItem {
  path: string
  component?: string
  children: any
}

interface IComponentProp extends RouteComponentProps<any> {
  sidebarOpen: boolean
  routeConf: any[]
}

class Main extends Component<IComponentProp, any> {
  constructor(props) {
    super(props)
  }

  public render() {
    // console.log('routerfuk', this.props.routeConf)
    const allRouter = this.props.routeConf || []
    return (
      <section className="layout-main">
        <div className="layout-main-crumbs bcfff">
          <Crumbs routeConf={this.props.routeConf} />
        </div>

        <div className="layout-main-contant bcfff">
          <div style={{ marginTop: '20px' }}>
            <Suspense fallback={<Loading />}>
              <Switch>
                {allRouter.map((menuItem: IRouterItem) => {
                  const route = (r: any) => {
                    const ComponentItem = r.component
                    return <Route path={r.path} component={ComponentItem} key={r.path} exact />
                  }
                  return menuItem.component ? route(menuItem) : menuItem.children.map((r: IRouterItem) => route(r))
                })}
                {allRouter.length && (
                  <Route
                    render={() => {
                      return <Redirect to={allRouter[0].children ? allRouter[0].children[0].path : allRouter[0].path} />
                    }}
                  />
                )}
                <Route component={ErrorComponent} routeConf={this.props.routeConf} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(Main)
