
    /**
    * 删除活动
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17422 {
  /**
   * (String)
   */
  activityNo?: string | number;
  /**
   * (String)
   */
  itemNo?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid17422 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17422, IResid17422 > = (data?) =>  request({
        method: 'GET',
        url: '/activityItem/del',
        params: data
      }) 
  export default http