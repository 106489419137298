
    /**
    * 前台查询活动商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItemQueryParam :DistributionItemQueryParam
 */
export class IReqid16700 {
  itemName?: {};
  categoryName?: {};
  minCommissionRate?: {};
  maxCommissionRate?: {};
  /**
   * Integer
   */
  promotionPlatform?: {}[];
  stockThresholdSize?: {};
  hasSample?: {};
  activityNo?: {};
  index: {};
  size: {};
}
  /**
 * Response :Response
 */
export class IResid16700 {
  message?: {};
  code?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16700, IResid16700 > = (data?) =>  request({
        method: 'POST',
        url: '/item/activity/list',
        data: data
      }) 
  export default http