
    /**
    * 分销员业绩列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18174 {
  /**
   * bd组名
   */
  name?: string | number;
  /**
   * 组编号
   */
  bdGroupCode?: string | number;
  /**
   * 分销员code
   */
  bdCode?: string | number;
  /**
   * 省份province_id
   */
  provinceCode?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<DistributionBdDTO>> :YResult
 */
export class IResid18174 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 组编号
       */
      bdGroupCode?: {};
      /**
       * BD组名称
       */
      bdGroupName?: {};
      /**
       * bd编号
       */
      bdCode?: {};
      /**
       * bd名称
       */
      bdName?: {};
      /**
       * 拥有主播数
       */
      anchorNum?: {};
      /**
       * 已直播场数
       */
      overLiveNum?: {};
      /**
       * 待直播场数
       */
      waitLiveNum?: {};
      /**
       * 直播转化率
       */
      liveConversionRate?: {};
      /**
       * 复播率
       */
      rebroadcastRate?: {};
      /**
       * 总gvm
       */
      totalGmv?: {};
      /**
       * 周环比增长
       */
      weekRate?: {};
      /**
       * 月环比增长
       */
      monthRate?: {};
      /**
       * 查看次数
       */
      viewCount?: {};
      /**
       * 分享次数
       */
      shareCount?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18174, IResid18174['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGmv/list/performance',
        params: data
      }) 
  export default http