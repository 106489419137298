
    /**
    * 添加活动商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ActivityItemAddParam :ActivityItemAddParam
 */
export class IReqid14020 {
  activityNo?: {};
  /**
   * String
   */
  selectedItemIds?: {}[];
}
  /**
 * YResult :YResult
 */
export class IResid14020 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14020, IResid14020 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/activity/item/add',
        data: data
      }) 
  export default http