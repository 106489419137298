
    /**
    * 单个保存pid信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16970 {
  /**
   * pid类型 1 淘宝 2 有赞 3快手 4抖音
   */
  pidType: number;
  pid: string;
}
  /**
 * Response<Void> :Response
 */
export class IResid16970 {
  message?: {};
  code?: {};
  /**
   * Void
   */
  data?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16970, IResid16970['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/api/anchor/save_anchor_pid_info',
        data: data
      }) 
  export default http