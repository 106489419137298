
    /**
    * 收发货接口
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ReceiptSampleParams :ReceiptSampleParams
 */
export class IReqid18652 {
  /**
   * 样品状态1-发货2收货
   */
  receiptSampleStatus?: {};
  /**
   * 计划样品编号 ,String
   */
  liveSampleNos?: {}[];
}
  /**
 * YResult :YResult
 */
export class IResid18652 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18652, IResid18652 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/sampleManage/receiptOrSendSample',
        data: data
      }) 
  export default http