import { getBiteLen } from './index'

const isNullValues = (value: any): boolean => [null, undefined, ''].indexOf(value) > -1

export default {
  isNullValues,
  tempMoney: (value: string): string => {
    if (isNullValues(value) || value === '--') return ''
    const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
    return reg.test(value.toString()) ? '' : '金钱格式有误'
  },
  money: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
    return reg.test(value.toString()) ? '' : '金钱格式有误'
  },

  integerZ: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^[1-9]\d*$/
    return reg.test(value.toString()) ? '' : '请输入正整数'
  },

  integerZ0: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^[0-9]\d*$/
    return reg.test(value.toString()) ? '' : '请输入非负整数'
  },
  integerN0: (value: number | string, limit: number, max: number): string => {
    if (isNullValues(value)) return ''

    if (max && Number(value) > max) return `请输入不得超过${max}的数字`
    const reg = new RegExp(`^(0|[1-9][0-9]{0,7})(\\.\\d{1,${limit}})?$`)
    return reg.test(value.toString()) ? '' : `请输入正数且不超过${limit}位小数`
  },
  telephone: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^(13|14|15|16|18|17|19)[0-9]{9}$/
    return reg.test(value.toString()) ? '' : '请输入正确手机号'
  },
  notChinese: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /[^\u4e00-\u9fa5]/g
    return reg.test(value.toString()) ? '' : '请输入非汉字字符'
  },
  chineseNum: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^([\u4e00-\u9fa5]|\d)+$/
    return reg.test(value.toString()) ? '' : '请输入中文或数字'
  },
  EnglishNum: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^([a-zA-Z]|\d)+$/
    return reg.test(value.toString()) ? '' : '请输入英文或数字'
  },
  englishAndNum: (value: number | string): boolean => {
    if (isNullValues(value)) return false
    const reg = /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{2,}$/
    return !reg.test(value.toString())
  },
  CnEnNum: (value: number | string): string => {
    if (isNullValues(value)) return ''
    const reg = /^([\u4e00-\u9fa5a-zA-Z]|\d)+$/
    return reg.test(value.toString()) ? '' : '请输入中文、英文或数字'
  },
  checkBiteLength: (value: string, limit: number) => {
    if (isNullValues(value)) return ''
    const length = getBiteLen(value)
    return length <= limit ? '' : '字符过长！'
  },
}
