
    /**
    * 详情页加入待播单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14715 {
  itemNo: string;
}
  export class IResid14715 {
  code?: number;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14715, IResid14715 > = (data?) =>  request({
        method: 'POST',
        url: '/api/WaitingLiveItem/saveWaitingLiveItemForDetail',
        data: data
      }) 
  export default http