
    /**
    * 修改计划提交
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14345 {
  /**
   * 直播时间
   */
  liveTime: string;
  /**
   * 样品配送方式：1-自提 2-快递 3-无需样品
   */
  sampleDeliveryType: string;
  /**
   * 样品间编号（配送方式为自提时必填）
   */
  sampleRoomNo: string;
  /**
   * 收货地址编号（配送方式为快递时必填）
   */
  addressNo: string;
  /**
   * 商品列表
   */
  sampleItemList: {
    /**
     * 商品编号
     */
    sampleItemNo: string;
    sampleSkuList: {
      sampleSkuNo: string;
      /**
       * 1-待审批 10-申请取消
       */
      sampleStatus: number;
    }[];
    /**
     * 1-待审批 5-申请取消
     */
    auditStatus: number;
  }[];
  /**
   * 备注
   */
  remark: string;
  /**
   * 计划编号
   */
  livePlanNo: string;
  /**
   * 是否取消计划：0-否 1-是
   */
  isCancelPlan: number;
  /**
   * 更新时间
   */
  updateTime: string;
}
  export class IResid14345 {
  code: string;
  msg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14345, IResid14345 > = (data?) =>  request({
        method: 'POST',
        url: '/front/live/plan/modifyPlan',
        data: data
      }) 
  export default http