
    /**
    * 获取所有领域
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16975 {}
  /**
 * YResult<List<RealmInfoDto>> :YResult
 */
export class IResid16975 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * RealmInfoDto
   */
  data?: {
    /**
     * 领域编号
     */
    type?: {};
    /**
     * 领域名称
     */
    name?: {};
  }[];
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16975, IResid16975['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/anchor/list_realm_info',
        params: data
      }) 
  export default http