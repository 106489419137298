import React from 'react'
import qs from 'query-string'
import { RouteComponentProps } from 'react-router-dom'
import Aside from './layout/Aside'
// import Header from './layout/Header'
import Main from './layout/Main'
import InternalRouteConf from '../../router/internal.route.conf'
import EnvConfig from '@config/config'
import logo from '@assets/logo.png'
import API from '@endApi/index'
import { cache } from '@utils/Cache'
import { logout } from '../../services'
import { loading } from '@components/Loading'
import { Layout } from 'antd'
import { connect } from 'react-redux'
// @ts-ignore
import { setRole } from '@src/store/actions'

const { Content } = Layout
loading('')
type IProps = RouteComponentProps<any>

class Home extends React.Component<IProps, {}> {
  state = {
    routeConf: [],
  }

  async componentDidMount() {
    const queryParams = qs.parse(window.location.search)
    // 从localstorage中获取新的platform值
    const NEW_LOCAL_PLATFORM = cache.platform

    if (queryParams.token && NEW_LOCAL_PLATFORM === 'internal') {
      cache.set('token', queryParams.token)
    }

    // 如果无当前用户登录的数据的话跳转至相对应系统的登录界面
    if (NEW_LOCAL_PLATFORM === 'internal' && !cache.token) {
      logout()
    }

    if (cache.token && NEW_LOCAL_PLATFORM === 'internal') {
      // 存储用户信息
      await API.id13370().then(res => {
        cache.set('user', res as any)
      })
    }

    // 权限控制
    const getAuthedKeys = async () => {
      const permission: any = await API.id13375()
      const generatePermissionMenus = async (permissions: any) => {
        let roles = {}
        const flatPermission = permissions => {
          let urls = []
          permissions.forEach(elem => {
            // TODO 判断权限是否是本系统
            elem.url && urls.push(elem.url)
            if (elem.resourceType === 'BUTTON') {
              roles[elem.url] = true
            }
            if (elem.childMenus) {
              const childUrls = flatPermission(elem.childMenus)
              urls = [...urls, ...childUrls]
            }
          })
          return urls
        }
        const menus = await flatPermission(permissions)
        this.props.setRole(roles)
        return menus
      }

      const rows = await generatePermissionMenus((permission && permission.list) || [])
      return rows || []
    }

    const getPerssionRouteConf = async (RouteConf: any) => {
      const authedKeys = await getAuthedKeys()
      let route = RouteConf
      // 删除非权限路由
      route.forEach((item, index) => {
        if (!authedKeys.includes(item['meta'].permissionId)) {
          delete route[index]
        } else if (item.children) {
          item.children.forEach((child, _index) => {
            if (!authedKeys.includes(child['meta'].permissionId)) {
              delete route[index].children[_index]
            }
          })
        }
      })
      return route.filter(item => item)
    }

    // 根据不同的系统展示不同的路由规则
    const routeConf =
      EnvConfig['APP_ENV'] !== 'mock'
        ? await getPerssionRouteConf(InternalRouteConf)
        : // : await getPerssionRouteConf(InternalRouteConf)
          InternalRouteConf
    const state = this.state
    state.routeConf = routeConf
    this.setState(state)
  }

  render() {
    const AsideProps = {
      logo,
      routeConf: this.state.routeConf,
    }
    loading('hide')
    return (
      <>
        <Layout style={{ height: '100%' }}>
          <Aside {...AsideProps} />
          {/* <Header routeConf={this.state.routeConf} /> */}
          <Content>
            <Main routeConf={this.state.routeConf} />
          </Content>
        </Layout>
      </>
    )
  }
}

let mapStateToProps = (state: any) => {
  return {
    role: state.role,
  }
}

export default connect(mapStateToProps, { setRole })(Home)
