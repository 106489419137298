
    /**
    * 分销结算列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17700 {
  /**
   * 多个主播账号用逗号分割
   */
  anchorNo?: string | number;
  /**
   * 直播时间
   */
  liveStartDate?: string | number;
  liveEndDate?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<DistributionBalanceDTO>> :YResult
 */
export class IResid17700 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      id?: {};
      /**
       * 编号
       */
      uniqueNo?: {};
      /**
       * 主播账号
       */
      anchorNo?: {};
      /**
       * 主播昵称
       */
      anchorName?: {};
      /**
       * 直播时间
       */
      liveDate?: {};
      /**
       * CPS订单金额
       */
      cpsOrderMoney?: {};
      /**
       * 线上佣金金额
       */
      onlineCommission?: {};
      /**
       * 线下佣金金额
       */
      offlineCommission?: {};
      /**
       * 一件代发订单金额
       */
      daifaCommission?: {};
      /**
       * 一件代发商品成本
       */
      daifaItemCost?: {};
      /**
       * 一件代发运费成本
       */
      daifaShippingCost?: {};
      /**
       * 自有店铺订单金额
       */
      shopOrderCost?: {};
      /**
       * 平台服务费
       */
      platformCost?: {};
      /**
       * 自有店铺商品成本
       */
      shopItemCost?: {};
      /**
       * 自有店铺运费成本
       */
      shopShippingCost?: {};
      /**
       * 总利润
       */
      profitSum?: {};
      /**
       * 创建时间
       */
      createdAt?: {};
      /**
       * 更新时间
       */
      updatedAt?: {};
      /**
       * 是否删除，0.正常1.已删除
       */
      isDeleted?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17700, IResid17700['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/distributionBalance/pageList',
        params: data
      }) 
  export default http