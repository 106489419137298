
    /**
    * 临时token转为正式token（已废弃）
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13365 {
  /**
   * 临时token
   */
  token: string | number;
}
  export class IResid13365 {
  data: {
    userName: string;
    token: string;
  };
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13365, IResid13365['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/auth/login/token',
        params: data
      }) 
  export default http