
    /**
    * 删除活动
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13270 {}
  /**
 * YResult :YResult
 */
export class IResid13270 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  export default (activityNo: any,  data?: IReqid13270): AxiosPromise<IResid13270> => request({
        method: 'POST',
        url: '/admin/activity/delActivity/'+activityNo,
        data: data
      })