
    /**
    * 新增账号
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AccountSaveParam :AccountSaveParam
 */
export class IReqid17010 {
  /**
   * 账号
   */
  account?: {};
  /**
   * 用户手机号
   */
  mobileNumber: {};
  /**
   * 主播昵称
   */
  anchorName: {};
  /**
   * 密码
   */
  password?: {};
  /**
   * 主播头像
   */
  anchorImage?: {};
  /**
   * 主播类型
   */
  anchorType?: {};
  /**
   * 绑定主播账号
   */
  bindAccount?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17010 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17010, IResid17010 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/account/saveAccount',
        data: data
      }) 
  export default http