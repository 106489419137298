
    /**
    * 样品管理界面
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SampleManageSearchQueryParam :SampleManageSearchQueryParam
 */
export class IReqid14430 {
  sampleStatus?: {};
  itemNameOrBrandName?: {};
  planStatus?: {};
  liveTimeStart?: {};
  liveTimeEnd?: {};
  accountNo?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<SampleLivePlanViewDTO>> :YResult
 */
export class IResid14430 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * SampleLivePlanItemDetailViewDTO
       */
      sampleLivePlanItemDetailViewDTOS?: {
        itemName?: {};
        sampleItemNo?: {};
        sampleSkuNo?: {};
        imageStr?: {};
        shopType?: {};
        skuPropertyName?: {};
        categoryNo?: {};
        categoryName?: {};
        deliveryType?: {};
        sampleStatus?: {};
        shouldSelfPick?: boolean;
        returnTime?: {};
        timeOutDays?: {};
      }[];
      livePlanNo?: {};
      createTime?: {};
      liveTime?: {};
      /**
       * 预计到样时间
       */
      sampleGetTime?: {};
      sampleTotal?: {};
      sampleConfirmPending?: {};
      sampleDeliveryPending?: {};
      sampleReceivePending?: {};
      sampleReturnPending?: {};
      sampleReturnAlready?: {};
      sampleCancelAlready?: {};
      sampleTimeoutNotReturn?: {};
      /**
       * 收货地址 ,LivePlanAddressDto
       */
      livePlanAddressDto?: {
        /**
         * 地址编号
         */
        addressNo?: {};
        /**
         * 收货人
         */
        receiver?: {};
        /**
         * 手机号
         */
        phone?: {};
        /**
         * 省
         */
        province?: {};
        /**
         * 市
         */
        city?: {};
        /**
         * 区
         */
        district?: {};
        /**
         * 街道
         */
        street?: {};
        /**
         * 详细地址
         */
        detailAddress?: {};
      };
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14430, IResid14430['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleManage/list',
        data: data
      }) 
  export default http