
    /**
    * 获取用户信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13370 {}
  export class IResid13370 {
  data: {
    userName: string;
    userToken: string;
    userId: string;
  };
  code: string;
  msg: string;
  success: string;
  exceptionMsg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13370, IResid13370['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/auth/user/info',
        params: data
      }) 
  export default http