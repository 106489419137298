import * as React from 'react'
// @ts-ignore
import styles from './App.scss'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import Login from '@views/Retail/Login'
import RecoverPassword from '@views/Retail/RecoverPassword'
import Register from '@views/Retail/Register'
import ChangePassword from '@views/Retail/ChangePassword'
import Home from '@views/Home'
import RetailHome from '@views/RetailHome'
import comp404 from '@components/404'
import qs from 'query-string'
import EnvConfig from '@config/config'
import { cache } from '@utils/Cache'
import { loading } from '@components/Loading'
loading('')

interface IState {
  isLogin: boolean
}
class AppRouter extends React.Component<RouteComponentProps, IState> {
  constructor(props) {
    super(props)
  }

  UNSAFE_componentWillMount() {
    const CURRENT_HOST_NAME = window.location.hostname
    const queryParams = qs.parse(window.location.search)
    const RETAIL_CLIENT_HOSTNAME = new URL(EnvConfig.RETAIL_CLIENT_URL).hostname
    const INTERNAL_CLIENT_HOSTNAME = new URL(EnvConfig.INTERNAL_CLIENT_URL).hostname
    const API_BASE_URL_PATHNAME = new URL(EnvConfig.API_BASE_URL).pathname
    const LOCAL_PLATFORM = cache.platform
    let platform: any = ''

    // 判断当前使用的域名HOST头，是否与预设值一致 -> /config/env.ts
    switch (CURRENT_HOST_NAME) {
      case RETAIL_CLIENT_HOSTNAME:
        platform = 'retail'
        break
      case INTERNAL_CLIENT_HOSTNAME:
        platform = 'internal'
        break
      default:
        // 如果当前本地localstorage内无platform信息的话
        if (!LOCAL_PLATFORM) {
          platform = queryParams.platform && queryParams.platform === 'retail' ? 'retail' : 'internal'
        }

        // 如果当前本地localstorage有platform并且与url上的platform参数值不一致的话
        if (LOCAL_PLATFORM && queryParams.platform && LOCAL_PLATFORM !== queryParams.platform) {
          platform = queryParams.platform
        }

        // 如果是api是yapi前台地址的话切换到前台（防止页面报错）
        if (API_BASE_URL_PATHNAME === '/mock/121') {
          platform = 'retail'
        } else if (API_BASE_URL_PATHNAME === '/mock/124') {
          platform = 'internal'
        }

        break
    }
    // 如果有platform就写入至localstorage中
    platform && cache.set('platform', platform)
  }

  render() {
    loading('hide')
    const NEW_LOCAL_PLATFORM = cache.platform
    const AppWrapper = props => <div className={[styles.appWrapper].join(' ')}>{props.children}</div>
    return (
      <AppWrapper>
        {NEW_LOCAL_PLATFORM === 'internal' ? (
          <Switch>
            <Route path="/404" component={comp404} />
            <Route path="/" component={Home} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/recoverPassword" component={RecoverPassword} />
            <Route exact path="/changePassword" component={ChangePassword} />
            <Route exact path="/register" component={Register} />
            <Route path="/404" component={comp404} />
            <Route path="/" component={RetailHome} />
          </Switch>
        )}
      </AppWrapper>
    )
  }
}

export default AppRouter
