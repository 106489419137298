
    /**
    * 统计样品状态数量
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14795 {}
  /**
 * YResult<SampleSkuCountStatisticsDto> :YResult
 */
export class IResid14795 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * SampleSkuCountStatisticsDto
   */
  data?: {
    totalCount?: {};
    toConfirmedCount?: {};
    toDeliveryCount?: {};
    toGetCount?: {};
    toReturnCount?: {};
    sendBackCount?: {};
    returnCount?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14795, IResid14795['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/sampleManage/countSampleSkuByStatus',
        params: data
      }) 
  export default http