
    /**
    * 导出排期计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17413 {
  /**
   * 主播（昵称手机号微信）
   */
  anchorNo?: string | number;
  /**
   * bd组
   */
  bdGroupNo?: string | number;
  /**
   * 计划状态
   */
  planState?: string | number;
  /**
   * 起始时间
   */
  startTime?: string | number;
  /**
   * 分销人员
   */
  bdCode?: string | number;
  /**
   * 结束时间
   */
  endTime?: string | number;
  /**
   * 当前页
   */
  pageIndex?: string | number;
  /**
   * 当前页大小
   */
  pageSize?: string | number;
}
  export class IResid17413 {}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17413, IResid17413 > = (data?) =>  request({
        method: 'GET',
        url: '/export/scheduling_plan',
        params: data
      }) 
  export default http