import React, { ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
// @ts-ignore
import * as styles from './index.scss'
import { Tabs, Input, Form, Button } from 'antd'
import API from '@frontApi/index'
import logo from './images/login_logo.png'
import loginLeftBg from './images/login_left_bg.png'
import rules from '@utils/rules'
import qs from 'query-string'
import { cache } from '@utils/Cache'

const { TabPane } = Tabs

interface IProps {}

type loginFormKey = 'username' | 'password' | 'usernameError' | 'passwordError'

interface ILoginFormParams {
  username?: string
  password?: string
  usernameError?: string
  passwordError?: string
  countdown?: number
  phone?: string
  phoneError?: string
  vertify?: string
  vertifyError?: string
}

interface IState {
  userInfo: ILoginFormParams
  vertifyInfo: ILoginFormParams
}
class Login extends React.Component<IProps & RouteComponentProps, IState> {
  queryParams = qs.parse(window.location.search)

  state: IState = {
    userInfo: {
      username: '',
      usernameError: '',
      password: '',
      passwordError: '',
    },
    vertifyInfo: {
      phone: '',
      phoneError: '',
      vertify: '',
      vertifyError: '',
      countdown: 0,
    },
  }

  constructor(props: any) {
    super(props)
    this.login = this.login.bind(this)
    this.vertifyLogin = this.vertifyLogin.bind(this)
    this.getVetrify = this.getVetrify.bind(this)
    this.userLoginFormHandle = this.userLoginFormHandle.bind(this)
  }

  // public componentDidMount(): void {
  // const isLogin = Number(localStorage.getItem('isLogin') as string);
  // if (isLogin) {
  //   this.props.history.replace('/');
  // }
  // }

  public async login() {
    const state = this.state
    if (!state.userInfo.username) {
      this.state.userInfo['usernameError'] = '请输入您的手机号'
      this.setState(state)
      return
    } else {
      this.state.userInfo['usernameError'] = ''
      this.setState(state)
    }
    if (!state.userInfo.password) {
      this.state.userInfo['passwordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.userInfo.password)) {
      this.state.userInfo['passwordError'] = '密码必须字母数字组合'
      return
    } else {
      this.state.userInfo['passwordError'] = ''
      this.setState(state)
    }

    API.id12860({ mobile: this.state.userInfo.username, passWord: this.state.userInfo.password })
      .then(res => {
        cache.set('token', res['token'])
        cache.set(
          'user',
          {
            anchorName: res['anchorName'],
            anchorImage: res['anchorImage'],
            username: this.state.userInfo.username,
          } || {}
        )
        setTimeout(() => {
          if (this.queryParams && this.queryParams.redirect) {
            window.location.href = `${this.queryParams.redirect}`
          } else {
            window.location.href = window.location.origin
          }
        }, 1000)
      })
      .catch(() => {
        cache.clear()
      })
  }

  // 验证码登录
  public async vertifyLogin() {
    const state = this.state
    if (!state.vertifyInfo.phone) {
      this.state.vertifyInfo['phoneError'] = '请输入您的手机号'
      this.setState(state)
      return
    } else if (state.vertifyInfo.phone.length < 11) {
      this.state.vertifyInfo['phoneError'] = '请输入11位手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['phoneError'] = ''
      this.setState(state)
    }
    if (!state.vertifyInfo.vertify) {
      this.state.vertifyInfo['vertifyError'] = '请输入验证码'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['vertifyError'] = ''
      this.setState(state)
    }
    API.id12865({ mobile: this.state.vertifyInfo.phone, captcha: this.state.vertifyInfo.vertify })
      .then(res => {
        cache.set('token', res['token'])
        cache.set(
          'user',
          {
            anchorName: res['anchorName'],
            anchorImage: res['anchorImage'],
            username: this.state.vertifyInfo.phone,
          } || {}
        )
        setTimeout(() => {
          if (this.queryParams && this.queryParams.redirect) {
            window.location.href = `${this.queryParams.redirect}`
          } else {
            window.history.back()
          }
        }, 1000)
      })
      .catch(() => {
        cache.clear()
      })
  }

  public userLoginFormHandle(event: ChangeEvent) {
    const element = event.target as HTMLInputElement
    const { name, value } = element
    const state = this.state
    state.userInfo[name as loginFormKey] = value
    state.vertifyInfo[name as loginFormKey] = value
    this.setState(state)
  }

  public getVetrify() {
    const state = this.state
    if (!state.vertifyInfo.phone) {
      this.state.vertifyInfo['phoneError'] = '请输入您的手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['phoneError'] = ''
      this.setState(state)
    }

    API.id12870({ mobile: state.vertifyInfo.phone }).then(res => {
      this.state.vertifyInfo['countdown'] = 60
      const countdown = setInterval(() => {
        this.state.vertifyInfo['countdown']--
        this.setState(state)
        if (this.state.vertifyInfo['countdown'] === 0) {
          clearInterval(countdown)
          this.state.vertifyInfo['countdown'] = 0
        }
      }, 1000)
      this.setState(state)
    })
  }

  public render() {
    return (
      <div className={styles.wrap}>
        <div className={styles.box}>
          <div>
            <div className={styles.loginHeader}>
              <a href="/">
                <img width="150" height="40" src={logo} alt="" />
              </a>
              <a href="/">返回首页</a>
            </div>
            <div className={styles.loginBox}>
              <div className={styles.loginBoxLeft}>
                <img width="376" height="365" src={loginLeftBg} alt="" />
              </div>
              <div className={styles.loginBoxRight}>
                <h3>
                  <i></i>登录
                </h3>
                <a className={styles.logintoRegister} href="/register">
                  快速注册
                </a>
                <div className={styles.loginContent}>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="账号登录" key="1">
                      <Form>
                        <Form.Item
                          style={{ marginTop: 34 }}
                          name="username"
                          rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                          <div className={styles.phoneLabel}>
                            <i></i>手机号/账号
                          </div>
                          <Input
                            name="username"
                            maxLength={20}
                            type="text"
                            placeholder="请输入您的手机号/账号"
                            autoComplete="off"
                            onChange={this.userLoginFormHandle}
                          />
                          <p>
                            {this.state.userInfo.usernameError && (
                              <>
                                <i></i>
                                {this.state.userInfo.usernameError}
                              </>
                            )}
                          </p>
                        </Form.Item>
                        <Form.Item name="password">
                          <div className={styles.pwdLabel}>
                            <i></i>密码
                          </div>
                          <Input
                            name="password"
                            maxLength={12}
                            type="password"
                            placeholder="请输入密码"
                            autoComplete="off"
                            onChange={this.userLoginFormHandle}
                          />
                          <p>
                            {this.state.userInfo.passwordError && (
                              <>
                                <i></i>
                                {this.state.userInfo.passwordError}
                              </>
                            )}
                            <a href="/recoverPassword">找回密码</a>
                          </p>
                        </Form.Item>
                        <div className={styles.loginButton} onClick={this.login}>
                          登 录
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane tab="验证码登录" key="2">
                      <Form>
                        <Form.Item style={{ marginTop: 34 }} name="phone">
                          <div className={styles.phoneLabel}>
                            <i></i>手机号
                          </div>
                          <Input
                            name="phone"
                            maxLength={11}
                            type="text"
                            placeholder="请输入您的手机号"
                            autoComplete="off"
                            onChange={this.userLoginFormHandle}
                          />
                          <p>
                            {this.state.vertifyInfo.phoneError && (
                              <>
                                <i></i>
                                {this.state.vertifyInfo.phoneError}
                              </>
                            )}
                          </p>
                        </Form.Item>
                        <Form.Item>
                          <div className={styles.pwdLabel}>
                            <i></i>验证码
                          </div>
                          <Input
                            name="vertify"
                            maxLength={6}
                            type="text"
                            placeholder="请输入验证码"
                            autoComplete="off"
                            onChange={this.userLoginFormHandle}
                          />
                          <div className={styles.captcha}>
                            {!this.state.vertifyInfo.countdown ? (
                              <Button type="link" onClick={this.getVetrify}>
                                获取验证码
                              </Button>
                            ) : (
                              <Button type="link">{`${this.state.vertifyInfo.countdown}s`}</Button>
                            )}
                          </div>
                          <p>
                            {this.state.vertifyInfo.vertifyError && (
                              <>
                                <i></i>
                                {this.state.vertifyInfo.vertifyError}
                              </>
                            )}
                          </p>
                        </Form.Item>
                        <div className={styles.loginButton} onClick={this.vertifyLogin}>
                          登 录
                        </div>
                      </Form>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <p>浙ICP备12034747号-4 © 2020 YAOWANGXINGXUAN. All rigths reserved 2018</p>
        </div>
      </div>
    )
  }
}

export default Login
