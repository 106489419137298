import React, { ChangeEvent }  from 'react';
import { RouteComponentProps } from 'react-router-dom'
// @ts-ignore
import * as styles           from './index.scss'
import { Input, Form } from 'antd';
import API                   from '@frontApi/index';
import logo from './images/login_logo.png'
import loginLeftBg from './images/login_left_bg.png'
import rules from '@utils/rules'

interface IProps {

}

type loginFormKey = 'username' | 'password' | 'usernameError' | 'passwordError'

interface ILoginFormParams {
  password: string;
  passwordError: string;
  newPassword?: string;
  newPasswordError?: string;
  reNewPassword?: string;
  reNewPasswordError?: string
}

interface IState {
  vertifyInfo: ILoginFormParams;
}

class Login extends React.Component<IProps & RouteComponentProps, IState> {

  state: IState = {
    vertifyInfo: {
      password: '',
      passwordError: '',
      newPassword: '',
      newPasswordError: '',
      reNewPassword: '',
      reNewPasswordError: ''
    },
  };

  constructor(props: any) {
    super(props);
    this.changePwd = this.changePwd.bind(this);
    this.userLoginFormHandle = this.userLoginFormHandle.bind(this);
  }

  public userLoginFormHandle(event: ChangeEvent) {
    const element                          = event.target as HTMLInputElement;
    const { name, value }                  = element;
    const state                            = this.state;
    state.vertifyInfo[(name) as loginFormKey] = value;
    this.setState(state);
  }

  // 找回密码
  public changePwd() {
    const state = this.state
    if (!state.vertifyInfo.password) {
      this.state.vertifyInfo['passwordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.vertifyInfo.password)) {
      this.state.vertifyInfo['passwordError'] = '密码必须字母数字组合'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['passwordError'] = ''
      this.setState(state)
    }

    if (!state.vertifyInfo.newPassword) {
      this.state.vertifyInfo['newPasswordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.vertifyInfo.newPassword)) {
      this.state.vertifyInfo['newPasswordError'] = '密码必须字母数字组合'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['newPasswordError'] = ''
      this.setState(state)
    }

    if (!state.vertifyInfo.reNewPassword) {
      this.state.vertifyInfo['reNewPasswordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.vertifyInfo.reNewPassword)) {
      this.state.vertifyInfo['reNewPasswordError'] = '密码必须字母数字组合'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['reNewPasswordError'] = ''
      this.setState(state)
    }

    if (state.vertifyInfo.newPassword !== state.vertifyInfo.reNewPassword) {
      this.state.vertifyInfo['reNewPasswordError'] = '新密码不一致, 请重新输入'
      this.setState(state)
      return
    }

    API.id12875({oldPwd: state.vertifyInfo.password, newPwd: state.vertifyInfo.newPassword }).then(res => {
      window.history.back()
    })
  }

  public render() {
    return (
      <div className={styles.wrap}>
        <div className={styles.box}>
          <div>
            <div className={styles.loginHeader}>
              <a href="/"><img width="150" height="40" src={logo} alt="" /></a>
              <a href="/">返回首页</a>
            </div>
            <div className={styles.loginBox}>
              <div className={styles.loginBoxLeft}>
                <img width="376" height="365" src={loginLeftBg} alt=""/>
              </div>
              <div className={styles.loginBoxRight}>
                <h3><i></i>设置新密码</h3>
                <div className={styles.loginContent}>
                  <Form>
                    <Form.Item style={{marginTop: 34}}>
                      <div className={styles.pwdLabel}><i></i>原始密码</div>
                      <Input name="password" maxLength={12} type="password" placeholder="请输入您的新密码" autoComplete="off"
                             onChange={this.userLoginFormHandle}/>
                      <p>{this.state.vertifyInfo.passwordError && <>
                        <i></i>{this.state.vertifyInfo.passwordError}</>}</p>
                    </Form.Item>
                    <Form.Item>
                      <div className={styles.pwdLabel}><i></i>新密码</div>
                      <Input name="newPassword" maxLength={12} type="password" placeholder="请输入您的新密码" autoComplete="off"
                             onChange={this.userLoginFormHandle}/>
                      <p>{this.state.vertifyInfo.newPasswordError && <>
                        <i></i>{this.state.vertifyInfo.newPasswordError}</>}</p>
                    </Form.Item>
                    <Form.Item>
                      <div className={styles.pwdLabel}><i></i>确认新密码</div>
                      <Input name="reNewPassword" maxLength={12} type="password" placeholder="请输入您的新密码" autoComplete="off"
                             onChange={this.userLoginFormHandle}/>
                      <p>{this.state.vertifyInfo.reNewPasswordError && <>
                        <i></i>{this.state.vertifyInfo.reNewPasswordError}</>}</p>
                    </Form.Item>
                    <div className={styles.loginButton}>
                      <span className={styles.cancelButton} onClick={() => window.history.back()}>取消</span>
                      <span className={styles.nextButton} onClick={this.changePwd}>确定</span>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <p>浙ICP备12034747号-4 © 2020 YAOWANGXINGXUAN. All rigths reserved 2018</p>
        </div>
      </div>
    )
  }
}

export default Login;
