
    /**
    * 样品间管理列表-提供给前台调用
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17412 {
  sampleRoomNo?: string | number;
  /**
   * 样品间名称
   */
  sampleName?: string | number;
  /**
   * 负责人姓名
   */
  realName?: string | number;
  /**
   * 负责人联系方式
   */
  mobileNumber?: string | number;
  sampleAddress?: string | number;
  /**
   * 0正常
   */
  status?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<List<SampleRoomDTO>> :YResult
 */
export class IResid17412 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * SampleRoomDTO
   */
  data?: {
    id?: {};
    /**
     * 样品间名称
     */
    sampleName?: {};
    /**
     * 样品间地址
     */
    sampleAddress?: {};
    /**
     * 负责人姓名
     */
    realName?: {};
    /**
     * 负责人联系方式
     */
    mobileNumber?: {};
    /**
     * 样品间编号
     */
    sampleRoomNo?: {};
    /**
     * 0正常1不正常
     */
    status?: {};
    isDeleted?: {};
    createTime?: {};
    updateTime?: {};
  }[];
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17412, IResid17412['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/sampleRoom/list',
        params: data
      }) 
  export default http