
    /**
    * 获取BD组详情信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17210 {
  /**
   * (String)
   */
  code?: string | number;
}
  /**
 * YResult<BdGroupDetailDTO> :YResult
 */
export class IResid17210 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * BdGroupDetailDTO
   */
  data?: {
    /**
     * bd组唯一编码
     */
    code?: {};
    /**
     * bd组名
     */
    name?: {};
    /**
     * 省份province_id
     */
    provinceCode?: {};
    /**
     * 省份名称
     */
    provinceCodeName?: {};
    /**
     * 初始保护天数,-1为无期限
     */
    initProtect?: {};
    /**
     * 合作保护天数，-1为无期限
     */
    cooperateProtect?: {};
    /**
     * 分组人员 ,BdVo
     */
    bds?: {
      id?: {};
      groupCode?: {};
      code?: {};
      name?: {};
      createTime?: {};
      creator?: {};
      updateTime?: {};
      updator?: {};
    }[];
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17210, IResid17210['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGroup/detail',
        params: data
      }) 
  export default http