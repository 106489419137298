
    /**
    * 获取bd人员列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17417 {}
  /**
 * YResult<YListData<BdDto>> :YResult
 */
export class IResid17417 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 组编号
       */
      groupCode?: {};
      /**
       * bd编号
       */
      code?: {};
      /**
       * bd名称
       */
      name?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17417, IResid17417['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGroup/list_bd',
        params: data
      }) 
  export default http