
    /**
    * 统计待播单数量
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14170 {}
  /**
 * YResult<Integer> :YResult
 */
export class IResid14170 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * data
   */
  data?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14170, IResid14170['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/WaitingLiveItem/countWaitingLiveByAccountNo',
        params: data
      }) 
  export default http