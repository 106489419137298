
    /**
    * 验证码登陆
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid12865 {
  /**
   * (String)
   */
  mobile?: string | number;
  /**
   * (String)
   */
  captcha?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid12865 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid12865, IResid12865 > = (data?) =>  request({
        method: 'GET',
        url: '/account/loginByCaptcha',
        params: data
      }) 
  export default http