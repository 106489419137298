
    /**
    * 删除地址
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13210 {
  /**
   * 地址编号
   */
  addressNo: string | number;
}
  export class IResid13210 {
  code?: number;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13210, IResid13210 > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/del',
        params: data
      }) 
  export default http