
    /**
    * 超时未归还样品详情
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14195 {
  /**
   * 主播accountNo
   */
  accountNo?: string | number;
  /**
   * 是否允许借样1-是允许，0-不允许
   */
  enableGetSample?: string | number;
  /**
   * 分页
   */
  index?: string | number;
  size?: string | number;
}
  /**
 * YResult<List<SampleReturnTimeoutDetailDTO>> :YResult
 */
export class IResid14195 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * SampleReturnTimeoutDetailDTO
   */
  data?: {
    id?: {};
    /**
     * sample_item,商品名称
     */
    itemName?: {};
    sampleItemNo?: {};
    categoryNo?: {};
    categoryName?: {};
    salePriceMin?: {};
    /**
     * 审核状态：1-待审批、2-审批不通过、3-待确认、4-已确认、5-申请取消、6-已取消
     */
    auditStatus?: {};
    /**
     * 样品sku编号
     */
    sampleSkuNo?: {};
    /**
     * 样品sku状态：1-待审批、2-审批不通过、3-待确认、4-待发货、5-待自提、6-待收货（后台返回已发货）、7-待归还、8-已归还、9-无需归还、10-申请取消、11-已取消、12已寄回、13-超时未归还、14-超时已归还
     */
    sampleStatus?: {};
    /**
     * 售价范围-最大值
     */
    salePriceMax?: {};
    /**
     * sample_item,品牌名称
     */
    brandName?: {};
    /**
     * sample_item,关联的主播账号
     */
    accountNo?: {};
    /**
     * sample_logistics_record,sample_sku,预计到货时间=预计拿样时间
     */
    expectedArriveTime?: {};
    /**
     * sample_item,直播时间
     */
    liveTime?: {};
    /**
     * sample_sku,计划发货时间
     */
    expectedDeliveryTime?: {};
    /**
     * sample_sku,计划归还时间
     */
    expectedReturnTime?: {};
    /**
     * 计算,超时时间T
     */
    sampleReturnTimeout?: {};
    /**
     * 实际发货时间
     */
    actualDeliveryTime?: {};
    /**
     * 实际归还时间
     */
    actualReturnTime?: {};
    /**
     * 商品来源：1-商品中心2-小Y直播
     */
    itemFromSource?: {};
  }[];
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14195, IResid14195['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/sampleReturnTimeout/detail',
        params: data
      }) 
  export default http