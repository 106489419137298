
    /**
    * 创建计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * LivePlanSaveParam :LivePlanSaveParam
 */
export class IReqid14070 {
  /**
   * 主播编号
   */
  accountNo?: {};
  /**
   * 直播时间
   */
  liveTime?: {};
  /**
   * 样品配送方式：1-自提2-快递3-无需样品
   */
  sampleDeliveryType?: {};
  /**
   * 样品间编号（配送方式为自提时必填）
   */
  sampleRoomNo?: {};
  /**
   * 收货地址编号（配送方式为快递时必填）
   */
  addressNo?: {};
  /**
   * 备注
   */
  remark?: {};
  /**
   * 商品列表 ,SampleItem
   */
  sampleItemList?: {
    /**
     * 商品编号
     */
    sampleItemNo?: {};
    /**
     * 样品列表 ,SampleSku
     */
    sampleSkuList?: {
      /**
       * 样品编号
       */
      sampleSkuNo?: {};
    }[];
    /**
     * 是否配送样品0-不需要1-需要
     */
    isNeedSample?: {};
  }[];
}
  /**
 * YResult :YResult
 */
export class IResid14070 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14070, IResid14070 > = (data?) =>  request({
        method: 'POST',
        url: '/front/live/plan/createPlan',
        data: data
      }) 
  export default http