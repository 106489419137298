
    /**
    * 是否分销
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * List<BrandManageUpParam>
 */
export type IReqid18029 = {
  id?: {};
  /**
   * 品牌编号
   */
  brandNo?: {};
  /**
   * 供应商编号
   */
  supplierNo?: {};
  /**
   * 是否分销：0否1是
   */
  isDistri?: {};
}[];
  /**
 * YResult :YResult
 */
export class IResid18029 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18029, IResid18029 > = (data?) =>  request({
        method: 'POST',
        url: '/brandManage/updateIsDistri',
        data: data
      }) 
  export default http