
    /**
    * 修改排期状态
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SchedulingPlanStatusChangeParam :SchedulingPlanStatusChangeParam
 */
export class IReqid17397 {
  /**
   * 排期编号
   */
  schedulingPlanNo?: {};
  /**
   * 状态1-已直播2-已取消
   */
  status?: {};
  /**
   * 品牌对应Gmv ,PlanBrandGmvParam
   */
  planBrandGmvParams?: {
    /**
     * 业务编号
     */
    planBrandNo?: {};
    /**
     * 品牌实际Gmv
     */
    actualMoney?: {};
  }[];
}
  /**
 * YResult :YResult
 */
export class IResid17397 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17397, IResid17397 > = (data?) =>  request({
        method: 'POST',
        url: '/schedulingPlan/change_state',
        data: data
      }) 
  export default http