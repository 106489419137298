
    /**
    * 新增排期
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AnchorScheduleParam :AnchorScheduleParam
 */
export class IReqid17212 {
  /**
   * 主键
   */
  id: {};
  /**
   * 主播标识昵称加手机号
   */
  anchorNo: {};
  /**
   * bd组唯一标识
   */
  bdGroupNo: {};
  /**
   * 直播时间
   */
  liveTime: {};
  /**
   * 计划状态,,@seeScheduleEnum
   */
  planState?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17212 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17212, IResid17212 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/schedules',
        data: data
      }) 
  export default http