
    /**
    * 品牌下拉列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17350 {
  /**
   * 1,按月2按日
   */
  timeType: string | number;
  /**
   * 时间0
   */
  time: string | number;
  /**
   * 排序方式排期数量正序plan_num_asc，排期数量倒序plan_num_desc，
   */
  sortType: string | number;
}
  /**
 * YResult<YListData<BrandSpinnerDto>> :YResult
 */
export class IResid17350 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 品牌名称
       */
      brandValue?: {};
      /**
       * 排期场次
       */
      liveCount?: {};
      /**
       * 直播间编号
       */
      liveRoomNo?: {};
      /**
       * 直播间名称
       */
      liveRoomName?: {};
      /**
       * 品牌logo
       */
      logoImage?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17350, IResid17350['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/list_brand_spinner',
        params: data
      }) 
  export default http