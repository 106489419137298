
    /**
    * 分页查询活动
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13265 {
  activityName?: string | number;
  status?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<ActivityDto>> :YResult
 */
export class IResid13265 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 活动编号
       */
      activityNo?: {};
      /**
       * 活动名称
       */
      activityName?: {};
      /**
       * 活动轮播图
       */
      bannerImage?: {};
      /**
       * 活动排序
       */
      sortNum?: {};
      /**
       * 活动状态：0下架1上架
       */
      status?: {};
      /**
       * 0否1是
       */
      isDeleted?: {};
      /**
       * 创建人
       */
      creator?: {};
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 上架时间
       */
      shelfTime?: {};
      /**
       * 下架时间
       */
      offshelfTime?: {};
      /**
       * 修改人
       */
      updator?: {};
      /**
       * 修改时间
       */
      updateTime?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13265, IResid13265['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/activity/pageList',
        params: data
      }) 
  export default http