
    /**
    * 上传资源
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13275 {
  /**
   * 上传文件
   */
  files: string;
  /**
   * 上传路径
   */
  uploadPath: string;
  /**
   * 文件类型，1音频2视频3其他
   */
  fileType: string;
  /**
   * 文件名
   */
  fileNames: string;
}
  /**
 * YResult<YListData> :YResult
 */
export class IResid13275 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {}[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13275, IResid13275['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/resource/uploadFiles',
        data: data
      }) 
  export default http