
    /**
    * 批量删除订单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * List<String>
 */
export type IReqid17810 = {}[];
  /**
 * YResult :YResult
 */
export class IResid17810 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17810, IResid17810 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/importOrder/del_batch',
        data: data
      }) 
  export default http