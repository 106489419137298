
    /**
    * 查看导入进度
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17308 {
  /**
   * (String)
   */
  uuid?: string | number;
}
  /**
 * YResult<ImportProcessDto> :YResult
 */
export class IResid17308 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * ImportProcessDto
   */
  data?: {
    msg?: {};
    total?: {};
    doneCount?: {};
    failCount?: {};
    successCount?: {};
    isEnd?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17308, IResid17308['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/resource/process',
        params: data
      }) 
  export default http