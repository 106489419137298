
    /**
    * 采销人员列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16925 {}
  /**
 * YResult<YListData<PurchasingPersonnelDTO>> :YResult
 */
export class IResid16925 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 采销人员名称
       */
      name?: {};
      /**
       * 0否1是
       */
      isDeleted?: {};
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 创建人
       */
      creator?: {};
      /**
       * 修改时间
       */
      updateTime?: {};
      /**
       * 修改人
       */
      updator?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16925, IResid16925['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/brandManage/list_purchasing_Selector',
        params: data
      }) 
  export default http