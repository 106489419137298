
    /**
    * 取消直播计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14065 {
  /**
   * (Date)
   */
  liveTime?: string | number;
}
  /**
 * YResult<PlanTimeDto> :YResult
 */
export class IResid14065 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * PlanTimeDto
   */
  data?: {
    /**
     * 直播时间
     */
    liveTime?: {};
    /**
     * 锁库存时间
     */
    sampleLockStartTime?: {};
    /**
     * 锁库存时间
     */
    sampleLockEndTime?: {};
    /**
     * 计划发货时间
     */
    expectedDeliveryTime?: {};
    /**
     * 计划收货时间
     */
    expectedGetTime?: {};
    /**
     * 计划归还时间
     */
    expectedReturnTime?: {};
    /**
     * 直播前X天收货
     */
    beforeZhibo?: {};
    /**
     * 直播后X天开始归还
     */
    afterZhibo?: {};
    /**
     * 发货天数（快递）
     */
    deliveryDays?: {};
    /**
     * 归还天数（快递）
     */
    returnDays?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14065, IResid14065['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/front/live/plan/calcPlanTime',
        params: data
      }) 
  export default http