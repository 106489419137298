
    /**
    * 取消单个样品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14380 {
  /**
   * 计划编号
   */
  livePlanNo: string;
  /**
   * 样品Sku编号
   */
  sampleSkuNo: string;
}
  export class IResid14380 {
  code: string;
  msg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14380, IResid14380 > = (data?) =>  request({
        method: 'POST',
        url: '/front/live/plan/cancelSampleSku',
        data: data
      }) 
  export default http