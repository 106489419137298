
    /**
    * 分页查询代播单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14120 {
  queryParam?: string | number;
  accountNo?: string | number;
  itemName?: string | number;
  branName?: string | number;
  itemNos?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<WaitingLiveItemDto>> :YResult
 */
export class IResid14120 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      waitingLiveNo?: {};
      /**
       * 唯一编号，系统内使用
       */
      itemNo?: {};
      /**
       * 商品图片
       */
      mainImage?: {};
      /**
       * 商品名称
       */
      itemName?: {};
      /**
       * 店铺类型：1-淘宝；2-有赞；3-快手；4-抖音；,使用“,”号分隔，如1,2,3
       */
      shopType?: {};
      /**
       * 售价(区间：255.00~300.00)
       */
      minScalePrice?: {};
      maxScalePrice?: {};
      /**
       * 佣金比率（区间：20%~30%）
       */
      minCommissionRate?: {};
      maxCommissionRate?: {};
      /**
       * 直播价区间
       */
      minSuggestPrice?: {};
      maxSuggestPrice?: {};
      /**
       * 供货库存
       */
      skuSize?: {};
      /**
       * 预估销量
       */
      estimatedSales?: {};
      /**
       * 商品状态：0-未上架；1-已上架
       */
      status?: {};
      /**
       * 是否需要样品
       */
      isNeedSample?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14120, IResid14120['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/WaitingLiveItem/pageListWaitingLive',
        params: data
      }) 
  export default http