
    /**
    * 跟进中主播下拉列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17399 {
  zhuboType?: string | number;
  /**
   * 1,按月2按日
   */
  timeType?: string | number;
  /**
   * 主播审核状态多个审核状态用","分开
   */
  auditingStatus?: string | number;
  /**
   * 时间0
   */
  time?: string | number;
  /**
   * 排序方式排期数量正序plan_num_asc，排期数量倒序plan_num_desc，
   */
  sortType?: string | number;
  /**
   * 主播来源1:分销注册，2:分销BD邀请3,xiaoy多个来源用，隔开
   */
  anchorSources?: string | number;
}
  /**
 * YResult<YListData<AnchorSpinnerDto>> :YResult
 */
export class IResid17399 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 主播编号
       */
      anchorNo?: {};
      /**
       * 主播名称
       */
      anchorValue?: {};
      /**
       * 排期场次
       */
      liveCount?: {};
      /**
       * 主播头像
       */
      img?: {};
      /**
       * bd组
       */
      bdGroupCode?: {};
      /**
       * bd
       */
      bdCode?: {};
      /**
       * 直播平台 ,Platform
       */
      platforms?: {
        platform?: {};
        accountId?: {};
      }[];
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17399, IResid17399['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/list_follow_anchor_spinner',
        params: data
      }) 
  export default http