
    /**
    * bd組下拉列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17218 {
  /**
   * 组名
   */
  groupName?: string | number;
  /**
   * 分銷人員
   */
  directors?: string | number;
  /**
   * 是否根据登录用户过滤0,否1是
   */
  isFilter?: string | number;
}
  /**
 * YResult<YListData<SimpleBdGroupInfoDto>> :YResult
 */
export class IResid17218 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * bd组唯一编码
       */
      groupCode?: {};
      /**
       * bd组名称
       */
      groupName?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17218, IResid17218['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGroup/list_bd_group',
        params: data
      }) 
  export default http