
    /**
    * 前台选品中心查询商品中心列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItemQueryParam :DistributionItemQueryParam
 */
export class IReqid13135 {
  itemName?: {};
  categoryName?: {};
  minCommissionRate?: {};
  maxCommissionRate?: {};
  /**
   * Integer
   */
  promotionPlatform?: {}[];
  stockThresholdSize?: {};
  hasSample?: {};
  activityNo?: {};
  index: {};
  size: {};
}
  /**
 * Response<PagedListView<DistributeItemSellSubmitDto>> :Response
 */
export class IResid13135 {
  message?: {};
  code?: {};
  /**
   * PagedListView
   */
  data?: {
    /**
     * T
     */
    list?: {
      uniqueNo?: {};
      mainImage?: {};
      itemName?: {};
      categoryNo?: {};
      categoryPath?: {};
      brandNo?: {};
      brandName?: {};
      shopType?: {};
      minCommissionRate?: {};
      maxCommissionRate?: {};
      skuSize?: {};
      salePoint?: {};
      salePointFile?: {};
      isSampleBack?: {};
      spuUniqueNo?: {};
      /**
       * 日常价-最低
       */
      minDailyPrice?: {};
      /**
       * 日常价-最高
       */
      maxDailyPrice?: {};
      /**
       * 直播价-最低
       */
      minSuggestPrice?: {};
      /**
       * 直播价-最高
       */
      maxSuggestPrice?: {};
      /**
       * 供货价-最低
       */
      minSupplyPrice?: {};
      /**
       * 供货价-最高
       */
      maxSupplyPrice?: {};
      /**
       * 是否独家
       */
      isOnly?: {};
      /**
       * 是否有样品
       */
      sampleSize?: {};
      /**
       * 是否是活动商品：0-否1-是
       */
      isAct?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
      isWaitingLiveItem?: {};
      /**
       * PlatformShopInfo
       */
      shopList?: {
        shopType?: {};
        /**
         * PlatformShopGoods
         */
        item?: {
          itemName?: {};
          shopName?: {};
        }[];
      }[];
    }[];
    total?: {};
    pages?: {};
    index?: {};
    size?: {};
  };
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13135, IResid13135['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/item/distribution/list',
        data: data
      }) 
  export default http