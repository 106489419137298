
    /**
    * 查询排期
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17342 {
  /**
   * 月份
   */
  month: string | number;
  /**
   * 主播
   */
  anchorNo: string | number;
  /**
   * 品牌对象
   */
  brandNo: string | number;
  /**
   * 直播间对象
   */
  liveRoomNo: string | number;
}
  /**
 * YResult<YListData<SchedulingPlanDto>> :YResult
 */
export class IResid17342 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 计划编号
       */
      uniqueNo?: {};
      /**
       * 直播间编号
       */
      liveRoomNo?: {};
      /**
       * 直播间备注
       */
      liveRoomRemark?: {};
      /**
       * 主播编号
       */
      anchorNo?: {};
      /**
       * 直播名称
       */
      anchorName?: {};
      /**
       * 主播备注
       */
      anchorRemark?: {};
      /**
       * 直播时间
       */
      liveDate?: {};
      /**
       * 直播间使用开始时间
       */
      beginTime?: {};
      /**
       * 直播间使用结束时间
       */
      endTime?: {};
      /**
       * 目标流水（预估GMV）
       */
      goalStatement?: {};
      /**
       * 计划投放金额
       */
      planMoney?: {};
      /**
       * 直播备注
       */
      remark?: {};
      /**
       * 直播主題
       */
      title?: {};
      /**
       * 分销人员
       */
      bdCode?: {};
      /**
       * 分销人员名称
       */
      bdName?: {};
      /**
       * 直播平台
       */
      platform?: {};
      /**
       * 实际gmv
       */
      actualMoney?: {};
      /**
       * 分销组编号
       */
      bdGroupCode?: {};
      /**
       * 分销组名称
       */
      bdGroupName?: {};
      /**
       * 计划状态0-带直播1-已直播2-已取消
       */
      status?: {};
      /**
       * 更新时间
       */
      updateTime?: {};
      /**
       * 合作方式：多个合作方式用";"隔开
       */
      cooperationTypes?: {};
      /**
       * 直播平台 ,Platform
       */
      platforms?: {
        platform?: {};
        accountId?: {};
      }[];
      /**
       * 计划品牌 ,PlanBrand
       */
      planBrands?: {
        /**
         * 业务编号
         */
        uniqueNo?: {};
        /**
         * 品牌实际Gmv
         */
        actualMoney?: {};
        /**
         * 品牌编号
         */
        brandNo?: {};
        /**
         * 品牌名称
         */
        brandName?: {};
        /**
         * 品牌备注
         */
        brandRemark?: {};
      }[];
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17342, IResid17342['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/list_scheduling_plan',
        params: data
      }) 
  export default http