
    /**
    * 获取当前账号的地址列表111
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14455 {}
  export class IResid14455 {
  code?: number;
  data?: {
    accountNo: string;
    addressNo: string;
    cityCode: number;
    cityCodeName: null | string;
    createTime: string;
    creator: string;
    detailAddress: string;
    districtCode: number;
    districtCodeName: null | string;
    id: number;
    isDefault: number;
    isDeleted: number;
    phone: string;
    postCode: string;
    provinceCode: number;
    provinceCodeName: null | string;
    receiver: string;
    streetCode: number;
    streetCodeName: null | string;
    updateTime: string;
    updator: string | null;
  }[];
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14455, IResid14455['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/list111',
        params: data
      }) 
  export default http