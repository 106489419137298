
    /**
    * 获取快递公司列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14200 {}
  export class IResid14200 {
  code?: number;
  message?: string;
  success?: boolean;
  data?: {
    /**
     * 快递公司名字
     */
    companyName: string;
    /**
     * 快递编号
     */
    companyCode: string;
  }[];
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14200, IResid14200['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/sampleReturnRecord/expressCompany/list',
        params: data
      }) 
  export default http