
    /**
    * 根据条件下拉主播列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17228 {
  userNo?: string | number;
  nickName?: string | number;
  zhuboType?: string | number;
  status?: string | number;
  mobile?: string | number;
  userNos?: string | number;
  auditingStatus?: string | number;
  zhuboBds?: string | number;
  /**
   * 主播来源 1:分销注册，2: 分销BD邀请 3,xiaoy 多个来源用，隔开
   */
  sources?: string | number;
}
  /**
 * YResult<YListData<ZhuboSimpleUserInfo>> :YResult
 */
export class IResid17228 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      userNo?: {};
      nickName?: {};
      zhuboType?: {};
      status?: {};
      headImg?: {};
      mobile?: {};
      weixinNo?: {};
      bdGroupCode?: {};
      bdGroupName?: {};
      auditingStatus?: {};
      name?: {};
      category?: {};
      zhuboBd?: {};
      /**
       * Platform
       */
      platforms?: {
        platform?: {};
        accountId?: {};
      }[];
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17228, IResid17228['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/anchor/list_zhubo_info',
        params: data
      }) 
  export default http