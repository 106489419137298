
    /**
    * 忘记密码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * ForgetPwdParam :ForgetPwdParam
 */
export class IReqid13060 {
  mobile?: {};
  captcha?: {};
  password?: {};
}
  /**
 * YResult :YResult
 */
export class IResid13060 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13060, IResid13060 > = (data?) =>  request({
        method: 'POST',
        url: '/account/forgetPwd',
        data: data
      }) 
  export default http