import API from '@endApi/index'
import frontAPI from '@frontApi/index'
import { cache } from '@utils/Cache'

const isLogin = cache.token
const platform = cache.platform
import EnvConfig from '@config/config'

const logout = async () => {
  switch (platform) {
    case 'retail':
      // 已登录调用登出接口; 登录超时或者未登录直接清除本地token
      isLogin && frontAPI.id13915()
      cache.clear()
      // removeToken()
      // removeUserInfo()
      break
    case 'internal':
      await API.id13385()
      cache.clear()
      // removeToken()
      // removeUserInfo()
      break
  }
  if (platform === 'internal') {
    let url = `${EnvConfig.YW_PLATFORM_URL}?projectId=PRODISTRIBUTE`
    // 本地开发回跳 打包环境跳入融合平台
    let href = window.location.href
    if (EnvConfig.NODE_ENV === 'development') {
      url += `&redirectUrl=${encodeURIComponent(href)}`
    }
    top.location.href = url
  } else {
    window.location.href = `${window.location.origin}/login?redirect=${window.location.href}`
  }
}

export { logout, isLogin }
