
    /**
    * 获取地址列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13030 {}
  /**
 * YResult :YResult
 */
export class IResid13030 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13030, IResid13030 > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/list',
        params: data
      }) 
  export default http