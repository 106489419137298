
    /**
    * 后台的分销商品库
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionBeItemQueryParam :DistributionBeItemQueryParam
 */
export class IReqid17405 {
  itemName?: {};
  brandNo?: {};
  categoryNo?: {};
  itemStatus?: {};
  promotionPlatform?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<ItemSupplyGoodsList4DistributionBeResp>> :YResult
 */
export class IResid17405 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      itemUniqueNo?: {};
      skuUniqueNo?: {};
      mainImage?: {};
      itemName?: {};
      brandNo?: {};
      brandName?: {};
      categoryNo?: {};
      categoryName?: {};
      propertyName?: {};
      propertyValue?: {};
      supplyPrice?: {};
      itemStatus?: {};
      isAct?: {};
      isOnly?: {};
      isAttach?: {};
      sampleSize?: {};
      /**
       * PlatformSkuPriceInfoDto
       */
      platformInfoList?: {
        shopType?: {};
        commissionRate?: {};
        dailyPrice?: {};
        suggestPrice?: {};
      }[];
      skuSize?: {};
      sampleSkuSize?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17405, IResid17405['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/item/distribution/backend/list',
        data: data
      }) 
  export default http