
    /**
    * 根据活动名称获取详细信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13285 {}
  /**
 * YResult<ActivityDto> :YResult
 */
export class IResid13285 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * ActivityDto
   */
  data?: {
    /**
     * 自增id
     */
    id?: {};
    /**
     * 活动编号
     */
    activityNo?: {};
    /**
     * 活动名称
     */
    activityName?: {};
    /**
     * 活动轮播图
     */
    bannerImage?: {};
    /**
     * 活动排序
     */
    sortNum?: {};
    /**
     * 活动状态：0下架1上架
     */
    status?: {};
    /**
     * 0否1是
     */
    isDeleted?: {};
    /**
     * 创建人
     */
    creator?: {};
    /**
     * 创建时间
     */
    createTime?: {};
    /**
     * 上架时间
     */
    shelfTime?: {};
    /**
     * 下架时间
     */
    offshelfTime?: {};
    /**
     * 修改人
     */
    updator?: {};
    /**
     * 修改时间
     */
    updateTime?: {};
  };
  exceptionMsg?: {};
}
  export default (activityNo: any,  data?: IReqid13285): AxiosPromise<IResid13285> => request({
        method: 'GET',
        url: '/admin/activity/getActivity/'+activityNo,
        params: data
      })