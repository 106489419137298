
    /**
    * 查询可切换域信息（已废弃）
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14540 {}
  export class IResid14540 {
  data: {
    /**
     * 域ID
     */
    domainId: string;
    /**
     * 域名字
     */
    domainName: string;
    /**
     * 跳转地址
     */
    domainUrl: string;
  }[];
  code: string;
  msg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14540, IResid14540['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/auth/user/biz',
        params: data
      }) 
  export default http