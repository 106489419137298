
    /**
    * BD管理列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17207 {
  /**
   * bd组名
   */
  name?: string | number;
  /**
   * 组编号
   */
  bdGroupCode?: string | number;
  /**
   * 分销员code
   */
  bdCode?: string | number;
  /**
   * 省份province_id
   */
  provinceCode?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<BdGroupDTO>> :YResult
 */
export class IResid17207 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 主键
       */
      id?: {};
      /**
       * bd组唯一编码
       */
      code?: {};
      /**
       * bd组名
       */
      name?: {};
      /**
       * 省份province_id
       */
      provinceCode?: {};
      /**
       * 省份名称
       */
      provinceCodeName?: {};
      /**
       * 初始保护天数,-1为无期限
       */
      initProtect?: {};
      /**
       * 合作保护天数，-1为无期限
       */
      cooperateProtect?: {};
      /**
       * 分组人员 ,BdVo
       */
      bds?: {
        id?: {};
        groupCode?: {};
        code?: {};
        name?: {};
        createTime?: {};
        creator?: {};
        updateTime?: {};
        updator?: {};
      }[];
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 拥有主播数
       */
      anchorNum?: {};
      /**
       * 已直播场数
       */
      overLiveNum?: {};
      /**
       * 待直播场数
       */
      waitLiveNum?: {};
      /**
       * 直播转化率
       */
      liveConversionRate?: {};
      /**
       * 复播率
       */
      rebroadcastRate?: {};
      /**
       * 总gvm
       */
      totalGMV?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17207, IResid17207['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGroup/list',
        params: data
      }) 
  export default http