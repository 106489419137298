/**
 * 环境配置
 */
export default {
  mock: {
    // API_BASE_URL: 'http://rc-admin.test.ywwl.com', // 测试
    API_BASE_URL: 'http://192.168.122.132:8101', // debug
    // API_BASE_URL: 'http://192.168.58.107:8041', // 唯一
    // API_BASE_URL: 'http://192.168.38.46:8041', // 方腊
    // API_BASE_URL: 'http://192.168.39.10:8041', // 露娜
    // API_BASE_URL: 'http://yapi.ywfe.com/mock/121',
    // API_BASE_URL: 'http://yapi.ywfe.com/mock/124',
    // 分销中心-[管理端]访问地址
    INTERNAL_CLIENT_URL: 'http://retail-manage.fed.ywwl.com',
    // 分销中心-[供应商端]访问地址
    RETAIL_CLIENT_URL: 'http://retail.fed.ywwl.com',
    // 融合平台地址
    YW_PLATFORM_URL: 'http://pl.fed.ywwl.com/login',
  },
  debug: {
    API_BASE_URL: 'http://192.168.122.132:8101',
    // 分销中心-[管理端]访问地址
    INTERNAL_CLIENT_URL: 'http://retail-manage-debug.fed.ywwl.com',
    // 分销中心-[分销商城端]访问地址
    RETAIL_CLIENT_URL: 'http://retail-debug.fed.ywwl.com',
    // 融合平台地址
    YW_PLATFORM_URL: 'http://pl.fed.ywwl.com/login',
  },
  test: {
    API_BASE_URL: 'https://test-api-distribution-center.ywwl.com',
    // 分销中心-[管理端]访问地址
    INTERNAL_CLIENT_URL: 'http://retail-admin.test.ywwl.com',
    // 分销中心-[分销商城端]访问地址
    RETAIL_CLIENT_URL: 'http://retail.test.ywwl.com',
    // 融合平台地址
    YW_PLATFORM_URL: 'http://framework.test.ywwl.com/login',
  },
  prod: {
    API_BASE_URL: 'https://api-distribution-center.ywwl.com',
    // 分销中心-[管理端]访问地址
    INTERNAL_CLIENT_URL: 'https://oper-xingxuan.ywwl.com',
    // 分销中心-[分销商城端]访问地址
    RETAIL_CLIENT_URL: 'https://xingxuan.ywwl.com',
    // 融合平台地址
    YW_PLATFORM_URL: 'https://yun.ywwl.com/login',
  },
}
