
    /**
    * 确认计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14325 {
  /**
   * 计划编号
   */
  livePlanNo: string;
  itemList: {
    sampleItemNo: string;
    itemName: string;
    sampleSkuList: {
      sampleSkuNo: string;
      skuProperty: string;
    }[];
  }[];
}
  export class IResid14325 {
  code: string;
  msg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14325, IResid14325 > = (data?) =>  request({
        method: 'POST',
        url: '/front/live/plan/confirmPlan',
        data: data
      }) 
  export default http