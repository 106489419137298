
    /**
    * 新增地址
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13245 {
  /**
   * 省份province_id
   */
  provinceCode?: string;
  /**
   * 市id
   */
  cityCode?: string;
  /**
   * 区id
   */
  districtCode?: string;
  /**
   * 街道id
   */
  streetCode?: string;
  /**
   * 联系地址
   */
  detailAddress?: string;
  /**
   * 邮政编码
   */
  postCode?: string;
  /**
   * 收货人
   */
  receiver?: string;
  /**
   * 手机号码
   */
  phone?: string;
  /**
   * 是否默认地址:0默认1不默认
   */
  isDefault?: string;
}
  export class IResid13245 {
  code?: number;
  data?: null;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13245, IResid13245['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/expressAddress/add',
        data: data
      }) 
  export default http