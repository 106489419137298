import React from 'react'
// @ts-ignore
import style from './index.scss'
import bg404 from './images/404.png'
import bg404Cloud from './images/404_cloud.png'

const Index = () => {
  return (
    <div className={style.wscn_http404_container}>
      <div className={style.wscn_http404}>
        <div className={style.pic_404}>
          <img className={style.pic_404__parent} src={bg404} alt="404" />
          <img className={`${style.pic_404__child} ${style.left}`} src={bg404Cloud} alt="404" />
          <img className={`${style.pic_404__child} ${style.mid}`} src={bg404Cloud} alt="404" />
          <img className={`${style.pic_404__child} ${style.right}`} src={bg404Cloud} alt="404" />
        </div>
        <div className={style.bullshit}>
          <div className={style.bullshit__oops}>OOPS!</div>
          <div className={style.bullshit__info}>技术支持: huowu@ywwl.com</div>
          <div className={style.bullshit__headline}>此页面不存在或者没有权限</div>
          <div className={style.bullshit__info}>请检查您输入的网址是否正确，请点击以下按钮返回主页</div>
          <a href="/" className={style.bullshit__return_home}>
            返回首页
          </a>
        </div>
      </div>
    </div>
  )
}

export default Index
