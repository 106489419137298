
    /**
    * 样品进度-快递/自提
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14300 {
  /**
   * 直播计划编号
   */
  livePlanNo: string;
}
  export class IResid14300 {
  code?: number;
  messsage?: string;
  data?: {
    list?: {
      expressCompanyName?: string;
      expressNo?: string;
      pkgTotal?: string;
      receiverPhone?: string;
      receiverName?: string;
      expectArriveTime?: string;
      receiverAddress?: string;
      submitPlanTime?: string;
      confirmPlanTime?: string;
      wsDeliveryTime?: string;
      confirmDeliveryTime?: string;
      itemDetail?: {
        itemName?: string;
        skuPropertyName?: string;
      }[];
      logisticsType?: string;
      logisticsStatus?: string;
      liveTime?: string;
    }[];
  };
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14300, IResid14300['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleLogisticsRecord',
        data: data
      }) 
  export default http