
    /**
    * 前台用户忘记密码验证验证码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13225 {
  /**
   * 手机号
   */
  mobile: string | number;
  /**
   * 验证码
   */
  captcha: string | number;
}
  export class IResid13225 {
  code?: number;
  data?: string;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13225, IResid13225['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/account/checkMtCode',
        params: data
      }) 
  export default http