
    /**
    * 修改样品间可用
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14260 {
  /**
   * (Integer)
   */
  enableGetSample?: string | number;
  /**
   * (String)
   */
  reason?: string | number;
  /**
   * (String)
   */
  accountNo?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid14260 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14260, IResid14260 > = (data?) =>  request({
        method: 'GET',
        url: '/sampleReturnTimeout/updaeEnableGetSample',
        params: data
      }) 
  export default http