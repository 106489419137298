/*
 * 一级路由 => /a
 * 二级路由 => /a/b
 * 注意: 二级路由的一级路径需与一级路由保持一致(面包屑需使用此规则)
 * */

import React from 'react'

const router = [
  // {
  //   path: '/index',
  //   name: 'index',
  //   meta: {
  //     title: '首页',
  //     icon: 'ScheduleOutlined',
  //     permissionId: 'index',
  //   },
  //   children: [],
  // },
  {
    path: '/accountManage',
    name: 'accountManage',
    meta: {
      title: '账号管理',
      icon: 'iconzhanghaoguanli',
      permissionId: 'accountManage',
    },
    component: React.lazy(() => import('../views/Internal/AccountManage')),
  },

  {
    path: '/anchorManage',
    name: 'anchorManage',
    meta: {
      title: '主播管理',
      icon: 'iconzhibo-zhubo',
      permissionId: 'anchorManage',
    },
    children: [
      {
        path: '/anchorManage/anchorManage',
        name: 'anchorManage',
        meta: {
          title: '主播管理',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/anchorManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/anchorManage')),
      },
      {
        path: '/anchorManage/auditAnchor',
        name: 'auditAnchor',
        meta: {
          title: '待审核',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/auditAnchor',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/auditAnchorManage')),
      },
      {
        path: '/anchorManage/scheduleManage',
        name: 'scheduleManage',
        meta: {
          title: '排期管理',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/scheduleManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/scheduleManage')),
      },
      {
        path: '/anchorManage/anchorAccountManage/:id',
        name: 'anchorAccountManage',
        hidden: true,
        meta: {
          title: '账号管理',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/anchorManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/anchorAccountManage')),
      },
      {
        path: '/anchorManage/addAnchor',
        name: 'addAnchor',
        hidden: true,
        meta: {
          title: '新增主播',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/anchorManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/addAnchor')),
      },
      {
        path: '/anchorManage/editAnchor/:anchorNo',
        name: 'editAnchor',
        hidden: true,
        meta: {
          title: '编辑主播',
          icon: 'ScheduleOutlined',
          permissionId: 'anchorManage/anchorManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/addAnchor')),
      },
    ],
  },
  {
    path: '/goodsManage',
    name: 'goodsManage',
    meta: {
      title: '商品管理',
      icon: 'iconshangpinguanli',
      permissionId: 'goodsManage',
    },
    children: [
      {
        path: '/goodsManage/distributionLibrary',
        name: 'retail',
        meta: {
          title: '分销商品库',
          icon: 'ScheduleOutlined',
          permissionId: 'goodsManage/distributionLibrary',
        },
        component: React.lazy(() => import('../views/Internal/GoodsManage/distributionLibrary')),
      },
    ],
  },
  {
    path: '/goodsManage/activeGoods',
    name: 'activeGoods',
    hidden: true,
    meta: {
      title: '活动商品',
      icon: 'ScheduleOutlined',
      permissionId: 'goodsManage/activeGoods',
    },
    component: React.lazy(() => import('../views/Internal/ActiveGoods')),
  },
  {
    path: '/bannerManage',
    name: 'bannerManage',
    meta: {
      title: 'banner管理',
      icon: 'iconwebbannerguanli',
      permissionId: 'bannerManage',
    },
    component: React.lazy(() => import('../views/Internal/BannerManage')),
  },
  {
    path: '/planManage',
    name: 'planManage',
    meta: {
      title: '计划管理',
      icon: 'iconjihuaguanli',
      permissionId: 'planManage',
    },
    component: React.lazy(() => import('../views/Internal/planManage')),
  },
  {
    path: '/sampleManage',
    name: 'sampleManage',
    meta: {
      title: '样品管理',
      icon: 'iconu38',
      permissionId: 'sampleManage',
    },
    children: [
      {
        path: '/sampleManage/sampleSetting',
        name: 'sampleSetting',
        meta: {
          title: '样品设置',
          icon: 'ScheduleOutlined',
          permissionId: 'sampleManage/sampleSetting',
        },
        component: React.lazy(() => import('../views/Internal/SampleManage/sampleSetting')),
      },
      {
        path: '/sampleManage/sampleMana',
        name: 'sampleMana',
        meta: {
          title: '样品管理',
          icon: 'ScheduleOutlined',
          permissionId: 'sampleManage/sampleMana',
        },
        component: React.lazy(() => import('../views/Internal/SampleManage/sampleMana')),
      },
      {
        path: '/sampleManage/returnManage',
        name: 'returnManage',
        meta: {
          title: '寄回管理',
          icon: 'ScheduleOutlined',
          permissionId: 'sampleManage/returnManage',
        },
        component: React.lazy(() => import('../views/Internal/SampleManage/returnManage')),
      },
      {
        path: '/sampleManage/unreturnManage',
        name: 'unreturnManage',
        meta: {
          title: '样品超时未归还',
          icon: 'ScheduleOutlined',
          permissionId: 'sampleManage/unreturnManage',
        },
        component: React.lazy(() => import('../views/Internal/SampleManage/unreturnManage')),
      },
      {
        path: '/sampleManage/receiveSend',
        name: 'receiveSend',
        meta: {
          title: '收发样品',
          icon: 'ScheduleOutlined',
          permissionId: 'sampleManage/receiveSend',
        },
        component: React.lazy(() => import('../views/Internal/SampleManage/receiveSend')),
      },
    ],
  },

  {
    path: '/businessManage',
    name: 'businessManage',
    meta: {
      title: '业务管理',
      icon: 'iconzhibo-zhubo',
      permissionId: 'businessManage',
    },
    children: [
      {
        path: '/businessManage/performanceManage',
        name: 'performanceManage',
        meta: {
          title: '分销员业绩表',
          icon: 'ScheduleOutlined',
          permissionId: 'businessManage/performanceManage',
        },
        component: React.lazy(() => import('@views/Internal/BusinessManage/performanceManage')),
      },
      {
        path: '/businessManage/BDManage',
        name: 'BDManage',
        meta: {
          title: '分销组管理',
          icon: 'ScheduleOutlined',
          permissionId: 'businessManage/BDManage',
        },
        component: React.lazy(() => import('@views/Internal/AnchorManage/BDManage')),
      },
    ],
  },

  {
    path: '/sampleRoomManage',
    name: 'sampleRoomManage',
    meta: {
      title: '样品间管理',
      icon: 'icondizhiguanli',
      permissionId: 'sampleRoomManage',
    },
    component: React.lazy(() => import('../views/Internal/SampleRoomManage')),
  },
  {
    path: '/liveRoomManage',
    name: 'liveRoomManage',
    meta: {
      title: '直播间管理',
      icon: 'iconzhibojianguanli',
      permissionId: 'liveRoomManage',
    },
    children: [
      {
        path: '/liveRoomManage/liveRoomList',
        name: 'liveRoomList',
        meta: {
          title: '直播间列表',
          icon: 'ScheduleOutlined',
          permissionId: 'liveRoomManage/liveRoomList',
        },
        component: React.lazy(() => import('../views/Internal/LiveRoomManage')),
      },
    ],
  },
  {
    path: '/liveSchedule',
    name: 'liveSchedule',
    meta: {
      title: '直播排期',
      icon: 'iconzhibopaiqiyuding',
      permissionId: 'liveSchedule',
    },
    children: [
      {
        path: '/liveSchedule/liveSchedulePlan',
        name: 'liveSchedulePlan',
        meta: {
          title: '直播计划',
          icon: 'ScheduleOutlined',
          permissionId: 'liveSchedule/liveSchedulePlan',
        },
        component: React.lazy(() => import('../views/Internal/LiveSchedule')),
      },
    ],
  },
  {
    path: '/orderManagement',
    name: 'orderManagement',
    meta: {
      title: '订单管理',
      icon: 'icondingdanguanli',
      permissionId: 'orderManagement',
    },
    children: [
      {
        path: '/orderManagement/distributeCPSorder',
        name: 'distributeCPSorder',
        meta: {
          title: '分销CPS订单',
          icon: 'ScheduleOutlined',
          permissionId: 'orderManagement/distributeCPSorder',
        },
        component: React.lazy(() => import('../views/Internal/OrderManagement/distributeCPSorder')),
      },
      {
        path: '/orderManagement/distributeOneItemOrder',
        name: 'distributeOneItemOrder',
        meta: {
          title: '分销一件代发订单',
          icon: 'ScheduleOutlined',
          permissionId: 'orderManagement/distributeOneItemOrder',
        },
        component: React.lazy(() => import('../views/Internal/OrderManagement/distributeOneItemOrder')),
      },
      {
        path: '/orderManagement/freeShopOrder',
        name: 'freeShopOrder',
        meta: {
          title: '自有店铺订单',
          icon: 'ScheduleOutlined',
          permissionId: 'orderManagement/freeShopOrder',
        },
        component: React.lazy(() => import('../views/Internal/OrderManagement/freeShopOrder')),
      },
    ],
  },
  {
    path: '/financialManagement',
    name: 'financialManagement',
    meta: {
      title: '财务管理',
      icon: 'iconcaiwuguanli',
      permissionId: 'financialManagement',
    },
    children: [
      {
        path: '/financialManagement/distributionSettlement',
        name: 'distributionSettlement',
        meta: {
          title: '分销结算',
          icon: 'ScheduleOutlined',
          permissionId: 'financialManagement/distributionSettlement',
        },
        component: React.lazy(() => import('../views/Internal/FinancialManagement')),
      },
    ],
  },
  {
    path: '/searchRecord',
    name: 'searchRecord',
    meta: {
      title: '搜索记录',
      icon: 'iconsousuojilu',
      permissionId: 'searchRecord',
    },
    component: React.lazy(() => import('../views/Internal/SearchRecord')),
  },
  {
    path: '/brandManagement',
    name: 'brandManagement',
    meta: {
      title: '品牌方管理',
      icon: 'iconpinpaifangguanli',
      permissionId: 'brandManagement',
    },
    component: React.lazy(() => import('../views/Internal/BrandManagement')),
  },
]

export default router
