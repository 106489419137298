
    /**
    * 发送注册验证码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16990 {
  /**
   * (String)
   */
  mobile?: string | number;
}
  /**
 * YResult<Long> :YResult
 */
export class IResid16990 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * data
   */
  data?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16990, IResid16990['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/account/verification_code',
        params: data
      }) 
  export default http