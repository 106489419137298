
    /**
    * 新建订单任务
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18153 {
  /**
   * 文件地址
   */
  fileUrl: string;
  /**
   * 文件名称
   */
  fileName: string;
  /**
   * 主播编号
   */
  anchorNo: string;
  /**
   * 直播时间
   */
  liveDate: string;
  /**
   * 合作模式
   */
  cooperationType: string;
  /**
   * 平台店铺90504630-遥望严选有攒点1961399263-奥洛菲快手小店
   */
  platformShop: string;
  /**
   * 订单类型1-cps2-一件代发3-自有店铺
   */
  orderType: string;
  /**
   * 创建人
   */
  creator: string;
}
  /**
 * YResult :YResult
 */
export class IResid18153 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18153, IResid18153 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/importOrderTask/save',
        data: data
      }) 
  export default http