import React, { ChangeEvent }  from 'react';
import { RouteComponentProps } from 'react-router-dom'
// @ts-ignore
import * as styles           from './index.scss'
import { Input, Form, Button } from 'antd';
import API                   from '@frontApi/index';
import logo from './images/login_logo.png'
import loginLeftBg from './images/login_left_bg.png'
import rules from '@utils/rules'

interface IProps {

}

type loginFormKey = 'username' | 'password' | 'usernameError' | 'passwordError'

interface ILoginFormParams {
  username: string;
  password: string;
  usernameError: string;
  passwordError: string;
  countdown?: number;
  isSetPwd?: boolean;
  newPassword?: string;
  reNewPassword?: string;
  newPasswordError?: string
  reNewPasswordError?: string
}

interface IState {
  vertifyInfo: ILoginFormParams;
}

class Login extends React.Component<IProps & RouteComponentProps, IState> {

  state: IState = {
    vertifyInfo: {
      username: '',
      usernameError: '',
      password: '',
      passwordError: '',
      countdown: 0,
      isSetPwd: false,
      newPassword: '',
      reNewPassword: '',
      reNewPasswordError: ''
    },
  };

  constructor(props: any) {
    super(props);
    this.getVetrify = this.getVetrify.bind(this);
    this.checkMtCode = this.checkMtCode.bind(this);
    this.forgetPwd = this.forgetPwd.bind(this);
    this.userLoginFormHandle = this.userLoginFormHandle.bind(this);
  }
  routerTo = (path: string) => {
    this.props.history.push(path)
  }

  public userLoginFormHandle(event: ChangeEvent) {
    const element                          = event.target as HTMLInputElement;
    const { name, value }                  = element;
    const state                            = this.state;
    state.vertifyInfo[(name) as loginFormKey] = value;
    this.setState(state);
  }

  public getVetrify() {
    const state = this.state
    if (!state.vertifyInfo.username) {
      this.state.vertifyInfo['usernameError'] = '请输入手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['usernameError'] = ''
      this.setState(state)
    }

    API.id12870({mobile: state.vertifyInfo.username}).then(res => {
      this.state.vertifyInfo['countdown'] = 60
      const countdown = setInterval(() => {
        this.state.vertifyInfo['countdown']--
        this.setState(state)
        if (this.state.vertifyInfo['countdown'] === 0) {
          clearInterval(countdown)
          this.state.vertifyInfo['countdown'] = 0
        }
      }, 1000)
      this.setState(state)
    })
  }

  public checkMtCode() {
    const state = this.state
    if (!state.vertifyInfo.username) {
      this.state.vertifyInfo['usernameError'] = '请输入手机号'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['usernameError'] = ''
      this.setState(state)
    }
    if (!state.vertifyInfo.password) {
      this.state.vertifyInfo['passwordError'] = '请输入验证码'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['passwordError'] = ''
      this.setState(state)
    }
    API.id13065({mobile: state.vertifyInfo.username, captcha: state.vertifyInfo.password }).then(res => {
      this.state.vertifyInfo['isSetPwd'] = true
      console.log(res)
    })
  }

  // 找回密码
  public forgetPwd() {
    const state = this.state
    if (!state.vertifyInfo.newPassword) {
      this.state.vertifyInfo['newPasswordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.vertifyInfo.newPassword)) {
      this.state.vertifyInfo['newPasswordError'] = '密码必须字母数字组合'
      this.setState(state)
      return
    }  else {
      this.state.vertifyInfo['newPasswordError'] = ''
      this.setState(state)
    }

    if (!state.vertifyInfo.reNewPassword) {
      this.state.vertifyInfo['passwordError'] = '请输入密码'
      this.setState(state)
      return
    } else if (rules.englishAndNum(state.vertifyInfo.reNewPassword)) {
      this.state.vertifyInfo['passwordError'] = '密码必须字母数字组合'
      this.setState(state)
      return
    } else {
      this.state.vertifyInfo['reNewPasswordError'] = ''
      this.setState(state)
    }

    if (state.vertifyInfo.newPassword !== state.vertifyInfo.reNewPassword) {
      this.state.vertifyInfo['reNewPasswordError'] = '密码不一致, 请重新输入'
      this.setState(state)
      return
    }

    API.id13060({mobile: state.vertifyInfo.username, password: state.vertifyInfo.newPassword, captcha: state.vertifyInfo.password }).then(res => {
      window.history.back()
    })
  }

  public render () {
    return (
      <div className={styles.wrap}>
        <div className={styles.box}>
          <div>
            <div className={styles.loginHeader}>
              <a href="/"><img width="150" height="40" src={logo} alt="" /></a>
              <a href="/">返回首页</a>
            </div>
            <div className={styles.loginBox}>
              <div className={styles.loginBoxLeft}>
                <img width="376" height="365" src={loginLeftBg} alt=""/>
              </div>
              <div className={styles.loginBoxRight}>
                <h3><i></i>{this.state.vertifyInfo['isSetPwd'] ? '设置密码' : '找回密码'}</h3>
                <div className={styles.loginContent}>
                  {
                    !this.state.vertifyInfo['isSetPwd'] ?
                      <Form>
                        <Form.Item style={{marginTop: 34}} name='username'>
                          <div className={styles.phoneLabel}><i></i>手机号</div>
                          <Input name="username" maxLength={11} type="text" placeholder="请输入您的手机号" autoComplete="off" onChange={this.userLoginFormHandle} />
                          <p>{this.state.vertifyInfo.usernameError && <><i></i>{this.state.vertifyInfo.usernameError}</>}</p>
                        </Form.Item>
                        <Form.Item name='password'>
                          <div className={styles.pwdLabel}><i></i>验证码</div>
                          <Input name="password" maxLength={6} type="password" placeholder="请输入验证码" autoComplete="off" onChange={this.userLoginFormHandle} />
                          <div className={styles.captcha}>
                            {!this.state.vertifyInfo.countdown ?
                              <Button type="link" onClick={this.getVetrify}>获取验证码</Button> :
                              <Button type="link">{`${this.state.vertifyInfo.countdown}s`}</Button>
                            }
                          </div>
                          <p>{this.state.vertifyInfo.passwordError && <><i></i>{this.state.vertifyInfo.passwordError}</>}</p>
                        </Form.Item>
                        <div className={styles.loginButton}>
                          <span className={styles.cancelButton} onClick={() => this.routerTo('login')}>取消</span>
                          <span className={styles.nextButton} onClick={this.checkMtCode}>下一步</span>
                        </div>
                      </Form> :
                      <Form>
                        <Form.Item style={{marginTop: 34}} name='newPassword'>
                          <div className={styles.phoneLabel}><i></i>设置新密码</div>
                          <Input name="newPassword" maxLength={12} type="password" placeholder="请输入您的新密码" autoComplete="off" onChange={this.userLoginFormHandle} />
                          <p>{this.state.vertifyInfo.newPasswordError && <><i></i>{this.state.vertifyInfo.newPasswordError}</>}</p>
                        </Form.Item>
                        <Form.Item name='reNewPassword'>
                          <div className={styles.pwdLabel}><i></i>验证新密码</div>
                          <Input name="reNewPassword" maxLength={12} type="password" placeholder="请输入您的新密码" autoComplete="off" onChange={this.userLoginFormHandle} />
                          <p>{this.state.vertifyInfo.reNewPasswordError && <><i></i>{this.state.vertifyInfo.reNewPasswordError}</>}</p>
                        </Form.Item>
                        <div className={styles.loginButton}>
                          <span className={styles.cancelButton} onClick={() => this.routerTo('login')}>取消</span>
                          <span className={styles.nextButton} onClick={this.forgetPwd}>确定</span>
                        </div>
                      </Form>
                  }
                </div>
              </div>
            </div>
          </div>
          <p>浙ICP备12034747号-4 © 2020 YAOWANGXINGXUAN. All rigths reserved 2018</p>
        </div>
      </div>
    )
  }
}

export default Login;
