
    /**
    * 寄回进度查看
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14310 {
  /**
   * 直播计划编号
   */
  livePlanNo: string;
}
  export class IResid14310 {
  code?: number;
  messsage?: string;
  data?: {
    expressCompanyName?: string;
    expressNo?: string;
    pkgTotal?: string;
    receiverPhone?: string;
    receiverName?: string;
    expectArriveTime?: string;
    receiverAddress?: string;
    /**
     * 开始寄回时间
     */
    startReturnTime?: string;
    /**
     * 样品核对时间
     */
    checkSampleTime?: string;
    /**
     * 仓库收货时间
     */
    wsDeliveryTime?: string;
    /**
     * 确认归还时间
     */
    confirmReturnTime?: string;
    itemDetail?: {
      itemName?: string;
      specification?: string;
    }[];
    logisticsType?: string;
    /**
     * 包裹目前所处状态
     */
    pkgStatus?: string;
    /**
     * 直播时间
     */
    liveTime: string;
  }[];
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14310, IResid14310['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleReturnProgress/list',
        data: data
      }) 
  export default http