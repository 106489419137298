
    /**
    * 主播下拉列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13325 {
  /**
   * (String)
   */
  anchorName?: string | number;
}
  /**
 * YResult<YListData<ZhuboSimpleUserInfo>> :YResult
 */
export class IResid13325 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      userNo?: {};
      nickName?: {};
      zhuboType?: {};
      status?: {};
      headImg?: {};
      mobile?: {};
      weixinNo?: {};
      bdGroupCode?: {};
      bdGroupName?: {};
      auditingStatus?: {};
      name?: {};
      category?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13325, IResid13325['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/account/selectListZhuBo',
        params: data
      }) 
  export default http