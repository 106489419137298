
    /**
    * 注册账号
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * RegisterAccountParam :RegisterAccountParam
 */
export class IReqid16995 {
  /**
   * 手机号
   */
  mobileNumber: {};
  /**
   * 密码
   */
  password: {};
  /**
   * 昵称
   */
  anchorName: {};
  /**
   * 验证码
   */
  verificationCode: {};
}
  /**
 * YResult :YResult
 */
export class IResid16995 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16995, IResid16995 > = (data?) =>  request({
        method: 'POST',
        url: '/account/register_account',
        data: data
      }) 
  export default http