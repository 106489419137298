
    /**
    * 修改密码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * UpdatePassWordParam :UpdatePassWordParam
 */
export class IReqid12875 {
  account?: {};
  oldPwd?: {};
  newPwd?: {};
}
  /**
 * YResult :YResult
 */
export class IResid12875 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid12875, IResid12875 > = (data?) =>  request({
        method: 'POST',
        url: '/account/updatePassWord',
        data: data
      }) 
  export default http