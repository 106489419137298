
    /**
    * 寄回样品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14210 {
  accountNo?: string;
  returnAddress?: string;
  receiverName?: string;
  receiverPhone?: string;
  expressCompany?: string;
  expressNo?: string;
  attachment?: string;
  detailParams: {
    /**
     * 直播计划编号
     */
    livePlanNo: string;
    sampleItemSkuDetails: {
      sampleItemNo: string;
      sampleSkuNo: string;
      sampleItemName: string;
      skuPropertyName: string;
      categoryNo: string;
      categoryName: string;
    }[];
  }[];
}
  export class IResid14210 {
  code?: number;
  message?: string;
  data?: null;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14210, IResid14210['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleReturnRecord',
        data: data
      }) 
  export default http