
    /**
    * 审核用户平台
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AnchorAuditingInfoParam :AnchorAuditingInfoParam
 */
export class IReqid16870 {
  /**
   * 主播编号
   */
  anchorNo: {};
  /**
   * bdGroupCode
   */
  bdGroupCode?: {};
  /**
   * 主播状态1:审核不通过，2:审批通过
   */
  status?: {};
  /**
   * bd编号
   */
  bdCode?: {};
}
  /**
 * YResult :YResult
 */
export class IResid16870 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16870, IResid16870 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/anchor/auditing_anchor_platform',
        data: data
      }) 
  export default http