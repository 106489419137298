
    /**
    * 样品寄回记录列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SampleReturnRecordQueryParam :SampleReturnRecordQueryParam
 */
export class IReqid14295 {
  phoneOrNickName?: {};
  accountNo?: {};
  sampleItemName?: {};
  sampleCategoryNo?: {};
  status?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult<YListData<SampleReturnRecordViewDTO>> :YResult
 */
export class IResid14295 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      sampleItemSkuName?: {};
      categoryName?: {};
      anchorNickName?: {};
      anchorType?: {};
      anchorPhone?: {};
      deliveryTime?: {};
      returnTime?: {};
      countDownReturnTime?: {};
      expressCompanyName?: {};
      expressNo?: {};
      imageStr?: {};
      realReturnTime?: {};
      status?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14295, IResid14295['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/sampleReturnRecord/list',
        data: data
      }) 
  export default http