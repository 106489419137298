
    /**
    * 重置密码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13880 {}
  /**
 * YResult :YResult
 */
export class IResid13880 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  export default (account: any,  data?: IReqid13880): AxiosPromise<IResid13880> => request({
        method: 'POST',
        url: '/admin/account/resetPassword/'+account,
        data: data
      })