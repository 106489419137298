
    /**
    * 通过编号获取代播单详情
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14130 {}
  /**
 * YResult<WaitingLiveItem> :YResult
 */
export class IResid14130 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * WaitingLiveItem
   */
  data?: {
    id?: {};
    waitingLiveNo?: {};
    /**
     * 账户
     */
    accountNo?: {};
    /**
     * spuid
     */
    itemNo?: {};
    updateTime?: {};
    /**
     * 商品名称
     */
    itemName?: {};
    /**
     * 品牌
     */
    brand?: {};
    /**
     * 预估销量
     */
    estimatedSales?: {};
    /**
     * 是否需要样品0-不需要1-需要
     */
    isNeedSample?: {};
    /**
     * 1是0否
     */
    isDeleted?: {};
    createTime?: {};
  };
  exceptionMsg?: {};
}
  export default (waitingLiveNo: any,  data?: IReqid14130): AxiosPromise<IResid14130> => request({
        method: 'GET',
        url: '/api/WaitingLiveItem/getWaitingLiveItem/'+waitingLiveNo,
        params: data
      })