
    /**
    * 导入Cps订单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17807 {
  /**
   * 主播编号
   */
  anchorNo: string;
  /**
   * 直播时间
   */
  liveDate: string;
  /**
   * 合作模式
   */
  cooperationType: string;
  /**
   * 平台店铺90504630-遥望严选有攒点1961399263-奥洛菲快手小店
   */
  platformShop: string;
  /**
   * 订单类型1-cps2-一件代发3-自有店铺
   */
  orderType: string;
  /**
   * 创建人
   */
  creator: string;
}
  /**
 * YResult<ImportDealResultDto> :YResult
 */
export class IResid17807 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * ImportDealResultDto
   */
  data?: {
    /**
     * 0-失败1-成功
     */
    dealResult?: {};
    /**
     * 下载地址
     */
    downloadUrl?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17807, IResid17807['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/importOrder/cps',
        data: data
      }) 
  export default http