
    /**
    * 删除计划
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * UniqueNoParamVO :UniqueNoParamVO
 */
export class IReqid17355 {
  uniqueNo?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17355 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17355, IResid17355 > = (data?) =>  request({
        method: 'POST',
        url: '/schedulingPlan/delete_scheduling_plan',
        data: data
      }) 
  export default http