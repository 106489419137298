
    /**
    * 修改BD组
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * BdGroupSaveParam :BdGroupSaveParam
 */
export class IReqid17208 {
  /**
   * bd组唯一编码,新增不传，修改需要传
   */
  code?: {};
  /**
   * bd组名
   */
  name?: {};
  /**
   * 省份province_id
   */
  provinceCode?: {};
  provinceCodeName?: {};
  /**
   * 初始保护天数
   */
  initProtect?: {};
  /**
   * 合作保护天数
   */
  cooperateProtect?: {};
  /**
   * -1为无期限
   */
  initProtectType?: {};
  /**
   * -1为无期限
   */
  cooperateProtectType?: {};
  /**
   * 分组人员 ,String
   */
  bds?: {}[];
}
  /**
 * YResult :YResult
 */
export class IResid17208 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17208, IResid17208 > = (data?) =>  request({
        method: 'POST',
        url: '/bdGroup/update',
        data: data
      }) 
  export default http