
    /**
    * 所有的一级类目ss
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17408 {}
  /**
 * Response :Response
 */
export class IResid17408 {
  message?: {};
  code?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17408, IResid17408 > = (data?) =>  request({
        method: 'GET',
        url: '/item/firstLevel/category',
        params: data
      }) 
  export default http