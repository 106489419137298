
    /**
    * 活动置顶
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17423 {
  /**
   * (String)
   */
  activityNo?: string | number;
  /**
   * (String)
   */
  itemNo?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid17423 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17423, IResid17423 > = (data?) =>  request({
        method: 'GET',
        url: '/activityItem/top',
        params: data
      }) 
  export default http