
    /**
    * 获取有权限的菜单列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13375 {}
  export class IResid13375 {
  code?: string;
  msg?: string;
  success?: boolean;
  data?: {
    list?: {
      resourceParentId: string;
      url: string;
      name: string;
      level: string;
      childMenus: {
        resourceParentId: string;
        url: string;
        name: string;
        level: string;
        childMenus: {}[];
      }[];
    }[];
  };
  exceptionMsg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13375, IResid13375['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/auth/user/menu',
        params: data
      }) 
  export default http