import React, { Component } from 'react'
import { Menu, Layout } from 'antd'
// import * as Icon                               from '@ant-design/icons'
import Icon from '@components/Icon'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons/lib";

const { Sider } = Layout

interface IComponentProp extends RouteComponentProps<any> {
  routeConf: any[]
  logo: any
  location: any
  history: any
}

class Aside extends Component<IComponentProp, object> {
  state = {
    menuName: `/${this.props.location.pathname.split('/')[1]}`,
    logo: require('@assets/logo_1.png'),
    collapsed: false,

  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  routerTo = (path: string) => {
    this.props.history.push(path)
  }

  openChange = value => {
    this.setState({
      menuName: value[value.length - 1],
    })
  }

  public render() {
    const pathname = this.props.location.pathname
    const routeConf = this.props.routeConf
    const MenuComponent = () => (
      <Menu
        mode="inline"
        defaultOpenKeys={[`/${pathname.split('/')[1]}`]}
        defaultSelectedKeys={[pathname]}
        openKeys={[this.state.menuName]}
        inlineCollapsed={true}
        // theme="dark"
        onOpenChange={this.openChange}
      >
        {routeConf.map((item: any) => {
          const NewIcon = <Icon type={item.meta.icon} />
          let ChildrenMenu = ({ childrenItem, ...other }: { childrenItem: any; key: any }) => {
            const ChildrenItemIcon = <Icon type={childrenItem.meta.icon} />
            return childrenItem.hidden ? (
              ''
            ) : childrenItem.children && childrenItem.children.length > 0 ? (
              <Menu.SubMenu
                key={childrenItem.path}
                icon={ChildrenItemIcon}
                title={
                  <span>
                    <span>{childrenItem.meta.title}</span>
                  </span>
                }
                {...other}
              >
                {childrenItem.children.map((menuItem: any) => {
                  if (!menuItem.hidden) {
                    return (
                      <Menu.Item key={menuItem.path}>
                        <Link to={menuItem.path}>{menuItem.meta.title}</Link>
                      </Menu.Item>
                    )
                  }
                })}
              </Menu.SubMenu>
            ) : (
              <Menu.Item icon={NewIcon} key={item.path} {...other} onClick={() => this.routerTo(item.path)}>
                <span>{item.meta.title}</span>
              </Menu.Item>
            )
          }
          return ChildrenMenu({ childrenItem: item, key: item.path })
        })}
      </Menu>
    )

    return (
      <Sider collapsed={this.state.collapsed} theme={'light'} style={{position: 'relative'}}>
        <div onClick={this.toggleCollapsed} style={{ position: 'absolute', left: 10, bottom: 10}}>
          {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </div>
        <aside className="layout-aside">
          {/* <div className="layout-aside-logo">
          <img width="80%" src={this.state.logo} alt="" />
          <span>分销平台</span>
        </div> */}
            <MenuComponent />
        </aside>
      </Sider>
    )
  }
}

export default withRouter(Aside)
