
    /**
    * 品牌方列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16920 {}
  /**
 * YResult<YListData<BrandManageSelectorDTO>> :YResult
 */
export class IResid16920 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
      /**
       * 品牌名称
       */
      brandName?: {};
      supplierName?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16920, IResid16920['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/brandManage/list_brand_Selector',
        params: data
      }) 
  export default http