
    /**
    * 后台所有的商品品牌
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17411 {}
  /**
 * YResult<YListData<BrandNameDto>> :YResult
 */
export class IResid17411 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      brandNo?: {};
      brandName?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17411, IResid17411['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/item/brand',
        params: data
      }) 
  export default http