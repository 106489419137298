
    /**
    * 前台的分销商品详情
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItemDetailQueryParam :DistributionItemDetailQueryParam
 */
export class IReqid13395 {
  itemId?: {};
  activityNo?: {};
  visitFrom?: {};
}
  /**
 * Response<ItemDetailDto> :Response
 */
export class IResid13395 {
  message?: {};
  code?: {};
  /**
   * ItemDetailDto
   */
  data?: {
    uniqueNo?: {};
    /**
     * String
     */
    imageList?: {}[];
    itemName?: {};
    detail?: {};
    isCountryExpressFree?: {};
    profitRateMin?: {};
    profitRateMax?: {};
    scalePriceMin?: {};
    scalePriceMax?: {};
    commissionRateMin?: {};
    commissionRateMax?: {};
    isAct?: {};
    isOnly?: {};
    sampleSize?: {};
    isSampleBack?: {};
    /**
     * Detail4DistributionSkuDto
     */
    skuDtos?: {
      /**
       * SkuProp
       */
      props?: {
        propertyName: {};
        propertyNo?: {};
        propertyValue: {};
        propertyValueNo?: {};
      }[];
      /**
       * PlatformSkuPriceInfoDto
       */
      platformInfoList?: {
        shopType?: {};
        commissionRate?: {};
        dailyPrice?: {};
        suggestPrice?: {};
      }[];
      skuSize?: {};
      skuUniqueNo?: {};
      deliveryTime?: {};
      discountType?: {};
      preStock?: {};
    }[];
  };
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13395, IResid13395['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/item/distribution/detail',
        data: data
      }) 
  export default http