
    /**
    * 品牌方详情
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16910 {
  /**
   * (String)
   */
  brandNo?: string | number;
  /**
   * (String)
   */
  supplierNo?: string | number;
}
  /**
 * YResult<BrandManageDetailDTO> :YResult
 */
export class IResid16910 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * BrandManageDetailDTO
   */
  data?: {
    /**
     * 自增id
     */
    id?: {};
    /**
     * 品牌编号
     */
    brandNo?: {};
    /**
     * 供应商编号
     */
    supplierNo?: {};
    /**
     * 助播
     */
    assistedSeeding?: {};
    /**
     * 主播要求
     */
    anchorAsk?: {};
    /**
     * 备注
     */
    remarks?: {};
    /**
     * 0否1是
     */
    isDeleted?: {};
    /**
     * 创建时间
     */
    createTime?: {};
    /**
     * 修改时间
     */
    updateTime?: {};
    /**
     * 可设置模式，多个以逗号分割1有赞CPS，2有赞分销，3抖音小店，4淘宝CPS，5快手分销
     */
    settableMode?: {};
    /**
     * 对外报的佣金,乘以100存整数
     */
    outCommission?: {};
    /**
     * 可直播平台,多个以逗号分割,1快手，2抖音，3淘宝
     */
    liveType?: {};
    /**
     * 直播场地列表 ,LivePlaceDTO
     */
    livePlaceList?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 场地类型1品牌方总部2蔚蓝品牌馆3专柜4其他
       */
      placeType?: {};
      /**
       * 场地编号
       */
      placeNo?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 场地名称
       */
      placeName?: {};
      /**
       * 省份province_id
       */
      provinceCode?: {};
      /**
       * 省份名称
       */
      provinceCodeName?: {};
      /**
       * 市id
       */
      cityCode?: {};
      /**
       * 市id名称
       */
      cityCodeName?: {};
      /**
       * 区id
       */
      districtCode?: {};
      /**
       * 区id名称
       */
      districtCodeName?: {};
      /**
       * 街道id
       */
      streetCode?: {};
      /**
       * 街道id名称
       */
      streetCodeName?: {};
      /**
       * 联系地址
       */
      detailAddress?: {};
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 修改时间
       */
      updateTime?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
    }[];
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16910, IResid16910['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/brandManage/detail',
        params: data
      }) 
  export default http