
    /**
    * 分配主播
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AnchorDirectorSaveParam :AnchorDirectorSaveParam
 */
export class IReqid16865 {
  /**
   * 主播名称
   */
  anchorNo?: {};
  /**
   * bd组编号
   */
  bdGroupCode?: {};
  /**
   * bd编号
   */
  bdCode?: {};
}
  /**
 * YResult :YResult
 */
export class IResid16865 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16865, IResid16865 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/anchor/save_anchor_assign_info',
        data: data
      }) 
  export default http