
    /**
    * 前台用户登陆
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13230 {
  /**
   * 手机号
   */
  mobile: string | number;
  /**
   * 密码
   */
  passWord: string | number;
}
  export class IResid13230 {
  code?: number;
  data?: {
    anchorImage?: string;
    anchorName?: string;
    token?: string;
  };
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13230, IResid13230['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/account/login',
        params: data
      }) 
  export default http