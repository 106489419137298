
    /**
    * 删除BD组
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17211 {
  /**
   * (String)
   */
  code?: string | number;
}
  /**
 * YResult :YResult
 */
export class IResid17211 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17211, IResid17211 > = (data?) =>  request({
        method: 'GET',
        url: '/bdGroup/delete',
        params: data
      }) 
  export default http