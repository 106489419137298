
    /**
    * 退出登录
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13385 {}
  export class IResid13385 {
  code?: string;
  msg?: string;
  success?: boolean;
  exceptionMsg?: string;
  data?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13385, IResid13385['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/auth/logout',
        params: data
      }) 
  export default http