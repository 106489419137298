import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Main from './layout/Main'
import RetailRouteConf from '../../router/retail.route.conf';
import "./index.scss";
type IProps = RouteComponentProps<any>

class Home extends React.Component<IProps, {}> {
  render() {
    return (
      <>
        <Main routeConf={RetailRouteConf} />
      </>
    )
  }
}

export default Home
