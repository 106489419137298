import React from 'react'

const router = [
  {
    path: '/',
    hidden: true,
    meta: {
      title: '欢迎',
      icon: 'line-chart',
    },
    component: React.lazy(() => import('../views/Retail/SelectGoods')),
  },
  {
    path: '/retail/personalCenter',
    hidden: true,
    meta: {
      title: '个人中心',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/PersonalCenter')),
  },
  {
    path: '/retail/goodsDetail',
    hidden: true,
    meta: {
      title: '商品详情',
      icon: 'line-chart',
    },
    component: React.lazy(() => import('../views/Retail/GoodsDetail')),
  },
  {
    path: '/retail/activeGoods',
    hidden: true,
    meta: {
      title: '活动商品',
      icon: 'line-chart',
    },
    component: React.lazy(() => import('../views/Retail/ActiveGoods')),
  },
  {
    path: '/retail/livePlan',
    hidden: true,
    meta: {
      title: '直播计划',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/LivePlan')),
  },
  {
    path: '/retail/sampleSchedule',
    hidden: true,
    meta: {
      title: '样品进度',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/SampleSchedule')),
  },
  {
    path: '/retail/planModify',
    hidden: true,
    meta: {
      title: '修改计划',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/PlanModify')),
  },
  {
    path: '/retail/cart',
    hidden: true,
    meta: {
      title: '我的待播单',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/Cart')),
  },
  {
    path: '/retail/addPlan',
    hidden: true,
    meta: {
      title: '加入直播计划',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/AddPlan')),
  },
  {
    path: '/retail/sampleProgress',
    hidden: true,
    meta: {
      title: '样品进度',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/SampleProgress')),
  },
  {
    path: '/retail/goodsManage',
    hidden: true,
    meta: {
      title: '样品管理',
      icon: 'line-chart',
    },
    needLogin: true,
    component: React.lazy(() => import('../views/Retail/GoodsManage')),
  },
]

export default router
