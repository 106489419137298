import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'
import { message } from 'antd'
import { logout } from '../services'
import EnvConfig from '@config/config'
import { cache } from '@utils/Cache'

interface RequestConfig extends AxiosRequestConfig {
  [key: string]: any
}

axios.defaults.baseURL = EnvConfig.API_BASE_URL
axios.interceptors.request.use(
  (config: RequestConfig) => {
    const platform = cache.platform
    config.headers.common['X-TOKEN'] = cache.token
    config.headers.common['PLATFORM'] = platform
    for (const key in config.params) {
      if (config.params[key] === null || config.params[key] === '') {
        delete config.params[key]
      }
    }
    for (const key in config.data) {
      if (config.data[key] === null || config.data[key] === '') {
        delete config.data[key]
      }
    }

    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    const platform = cache.platform
    if (EnvConfig.API_BASE_URL.indexOf('yapi.ywfe') > -1) {
      // console.log(response)
      return response.data.data
    }
    const { code, success } = response.data

    if (code === 0 || success) {
      return Promise.resolve(response.data.data)
    } else if (platform === 'retail' && code === 1023) {
      // 前台主播借样权限
      return Promise.resolve(response.data)
    }
    // token失效/未登录
    else if (code === 1000) {
      logout()
    }
    // 非 0 || -2 后台展示服务端提供的错误
    else {
      console.log(response.data)
      platform === 'internal'
        ? message.warn(response.data.msg || '服务正忙，请稍后再试~')
        : message.warn({
            content: response.data.msg || '服务正忙，请稍后再试~',
            duration: 0.5,
          })
      return platform === 'internal' ? Promise.reject(response.data.data) : Promise.reject(response.data)
    }
  },
  err => {
    message.error('服务正忙，请稍后再试~')
    return Promise.reject(err)
  }
)

export { AxiosPromise }
export default axios
