
    /**
    * 分页查询主播CRM/待审核主播
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16850 {
  /**
   * 主播名称,微信号，手机号
   */
  queryParam?: string | number;
  /**
   * 主播账号
   */
  anchorNo?: string | number;
  /**
   * 所在市
   */
  cityNo?: string | number;
  /**
   * 所在省
   */
  provinceNo?: string | number;
  /**
   * 分销人员账号
   */
  directorNo?: string | number;
  /**
   * 是否查询审核主播0:未审核主播1:已审核主播
   */
  anchorAuditing?: string | number;
  /**
   * bd组编号
   */
  groupCode?: string | number;
  /**
   * 是否拥有全部主播全限
   */
  allAnchorsRight?: string | number;
  /**
   * 类目
   */
  category?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<AnchorInfoDto>> :YResult
 */
export class IResid16850 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 主播编号
       */
      anchorNo?: {};
      /**
       * 基本信息 ,AnchorBaseInfoDto
       */
      anchorBaseInfoDto?: {
        /**
         * 主播编号
         */
        anchorNo?: {};
        /**
         * 用户手机号
         */
        mobileNumber?: {};
        /**
         * 主播昵称
         */
        nickname?: {};
        /**
         * 主播头像
         */
        image?: {};
        /**
         * 主播性别，0.男1.女
         */
        sex?: {};
        /**
         * 主播类型1-自有达人2-自有明星3-合作主播
         */
        type?: {};
        /**
         * 主播状态后台0:待审核1:审核不通过，2:跟进中，3:待分配,前台0:审核中1:审核不通过，2,3:审核通过
         */
        status?: {};
        /**
         * 省名
         */
        provinceName?: {};
        /**
         * 所在省
         */
        provinceNo?: {};
        /**
         * 市名
         */
        cityName?: {};
        /**
         * 所在市
         */
        cityNo?: {};
        /**
         * 主播来源1:注册，2:BD邀请
         */
        sources?: {};
        /**
         * 备注
         */
        remark?: {};
        /**
         * 创建时间
         */
        createdTime?: {};
        /**
         * 更新时间
         */
        updatedTime?: {};
        /**
         * 微信号
         */
        weixinNo?: {};
        /**
         * 联系人
         */
        contactUser?: {};
        /**
         * 联系方式
         */
        contactPhone?: {};
        /**
         * bd组
         */
        bdGroupCode?: {};
        /**
         * bd组名
         */
        bdGroupName?: {};
        /**
         * bd
         */
        bdCode?: {};
        /**
         * bd名称
         */
        bdName?: {};
        /**
         * 类目编号多个类目用;分开
         */
        category?: {};
        /**
         * 类目名称多个类目用,分开
         */
        categoryName?: {};
        /**
         * 是否中介0-否1-是
         */
        isAgent?: {};
      };
      /**
       * 主播认证平台 ,AnchorPlatformDto
       */
      anchorPlatforms?: {
        /**
         * 业务编号
         */
        platformAccountId?: {};
        /**
         * 主播账号
         */
        anchorNo?: {};
        /**
         * 平台类型1:快手，2:抖音，3：淘宝
         */
        type?: {};
        /**
         * 平台账号
         */
        platformAccount?: {};
        /**
         * 平台昵称
         */
        nickname?: {};
        /**
         * 粉丝数量
         */
        fansNumber?: {};
        /**
         * AnchorPidInfoDto
         */
        pidInfoDtos?: {
          /**
           * 平台id
           */
          platformAccountId?: {};
          /**
           * PID编号
           */
          adzoneNo?: {};
          /**
           * 直播平台：1:快手2:抖音3:小红书4:B站5:淘宝6:微博
           */
          platform?: {};
          /**
           * 联盟类型
           */
          pidType?: {};
          /**
           * PID
           */
          pid?: {};
        }[];
      }[];
      /**
       * 联系人联系方式 ,AnchorContactUser
       */
      contactUsers?: {
        /**
         * 联系人
         */
        contactUser?: {};
        /**
         * 联系方式
         */
        contactPhone?: {};
      }[];
      /**
       * 是否可以编辑0不能编辑1可以编辑
       */
      isEdit?: {};
      /**
       * 账号类型0,子账号，1主账号
       */
      accountType?: {};
      /**
       * 已直播常数
       */
      livedCount?: {};
      /**
       * 待直播场数
       */
      readyLiveCount?: {};
      /**
       * 最近一次直播时间
       */
      lastLiveTime?: {};
      /**
       * 最近一次代直播
       */
      lastReadyLiveTime?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16850, IResid16850['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/anchor/page_list_anchor_info',
        params: data
      }) 
  export default http