import './common.scss'
import '@babel/polyfill'
import * as React       from 'react'
import * as ReactDOM    from 'react-dom'
import { Provider } from "react-redux";
import store from "./store";
import {BrowserRouter}  from 'react-router-dom'
import {ConfigProvider} from 'antd'
import zh_CN            from 'antd/es/locale/zh_CN'
import moment           from 'moment'
import 'moment/locale/zh-cn'
import './utils/rem'

import App                   from './App'
import AppConfig             from './config/config'

console.warn(`当前环境：${AppConfig.APP_ENV}. 数据接口：${AppConfig.API_BASE_URL}`)

moment.locale('zh-cn');

const render = Component => {
    const element = (
        <ConfigProvider locale={zh_CN}>
            <Provider store={store}>
                <BrowserRouter>
                    <Component/>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    )
    ReactDOM.render(element, document.getElementById('root') as HTMLElement)
}
render(App);
