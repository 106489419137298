
    /**
    * 品牌方管理列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16900 {
  /**
   * 品牌编号
   */
  brandNo?: string | number;
  /**
   * 采销人员
   */
  purchasingPersonnel?: string | number;
  /**
   * 供应商编号
   */
  supplierNo?: string | number;
  /**
   * 是否分销：0否1是
   */
  isDistri?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<BrandManageDTO>> :YResult
 */
export class IResid16900 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
      /**
       * 商品数量
       */
      goodsNum?: {};
      /**
       * sku数量
       */
      skuNum?: {};
      /**
       * 助播
       */
      assistedSeeding?: {};
      /**
       * 主播要求
       */
      anchorAsk?: {};
      /**
       * 备注
       */
      remarks?: {};
      /**
       * 0否1是
       */
      isDeleted?: {};
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 修改时间
       */
      updateTime?: {};
      /**
       * 待直播数
       */
      waitLiveNum?: {};
      /**
       * 已直播场数
       */
      overLiveNum?: {};
      /**
       * 供应商名称
       */
      supplierName?: {};
      /**
       * 品牌名称
       */
      brandName?: {};
      isDistri?: {};
      settableMode?: {};
      /**
       * 采销人员 ,String
       */
      purchasingPersonnel?: {}[];
      /**
       * 直播场地列表 ,LivePlaceDTO
       */
      livePlaceList?: {
        /**
         * 自增id
         */
        id?: {};
        /**
         * 场地类型1品牌方总部2蔚蓝品牌馆3专柜4其他
         */
        placeType?: {};
        /**
         * 场地编号
         */
        placeNo?: {};
        /**
         * 品牌编号
         */
        brandNo?: {};
        /**
         * 场地名称
         */
        placeName?: {};
        /**
         * 省份province_id
         */
        provinceCode?: {};
        /**
         * 省份名称
         */
        provinceCodeName?: {};
        /**
         * 市id
         */
        cityCode?: {};
        /**
         * 市id名称
         */
        cityCodeName?: {};
        /**
         * 区id
         */
        districtCode?: {};
        /**
         * 区id名称
         */
        districtCodeName?: {};
        /**
         * 街道id
         */
        streetCode?: {};
        /**
         * 街道id名称
         */
        streetCodeName?: {};
        /**
         * 联系地址
         */
        detailAddress?: {};
        /**
         * 创建时间
         */
        createTime?: {};
        /**
         * 修改时间
         */
        updateTime?: {};
        /**
         * 供应商编号
         */
        supplierNo?: {};
      }[];
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16900, IResid16900['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/brandManage/list',
        params: data
      }) 
  export default http