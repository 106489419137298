
    /**
    * 查询主播分配记录
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16860 {}
  /**
 * YResult<YListData<AnchorAssignLogInfoDto>> :YResult
 */
export class IResid16860 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 主播账号
       */
      anchorNo?: {};
      /**
       * 操作人员账号
       */
      operatorNo?: {};
      /**
       * 操作人员名称
       */
      operator?: {};
      /**
       * 分销人员账号
       */
      directorNo?: {};
      /**
       * 分销人员名称
       */
      director?: {};
      /**
       * 创建时间
       */
      createdTime?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  export default (anchorNo: any,  data?: IReqid16860): AxiosPromise<IResid16860> => request({
        method: 'GET',
        url: '/admin/anchor/list_anchor_assign_log/'+anchorNo,
        params: data
      })