
    /**
    * 每月GMV列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18175 {
  /**
   * bd组名
   */
  name?: string | number;
  /**
   * 组编号
   */
  bdGroupCode?: string | number;
  /**
   * 分销员code
   */
  bdCode?: string | number;
  /**
   * 省份province_id
   */
  provinceCode?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<BdMonthGmvInfoDto>> :YResult
 */
export class IResid18175 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * gmv
       */
      gmv?: {};
      /**
       * 月
       */
      month?: {};
      /**
       * bd
       */
      bdCode?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18175, IResid18175['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/bdGmv/list/bdMonthGmv',
        params: data
      }) 
  export default http