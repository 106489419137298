
    /**
    * 分享增加查看次数
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AddViewCountParam :AddViewCountParam
 */
export class IReqid18646 {
  /**
   * 链接唯一标识
   */
  urlUniqueNo?: {};
}
  /**
 * YResult :YResult
 */
export class IResid18646 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18646, IResid18646 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/bdShareInfo/add_view_count',
        data: data
      }) 
  export default http