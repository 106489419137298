
    /**
    * 认证主播信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * AnchorInfoSaveParam :AnchorInfoSaveParam
 */
export class IReqid16980 {
  /**
   * 主播账号
   */
  anchorNo?: {};
  /**
   * 基本信息 ,AnchorBaseInfoParam
   */
  anchorBaseInfoParam?: {
    /**
     * 主播编号
     */
    anchorNo?: {};
    /**
     * 用户手机号
     */
    mobileNumber?: {};
    /**
     * 主播性别，0.男1.女
     */
    sex: {};
    /**
     * 省名
     */
    provinceName?: {};
    /**
     * 所在省
     */
    provinceNo: {};
    /**
     * 市名
     */
    cityName?: {};
    /**
     * 所在市
     */
    cityNo: {};
    /**
     * 备注
     */
    remark?: {};
    /**
     * 主播昵称
     */
    nickname: {};
    /**
     * 主播头像
     */
    image?: {};
    /**
     * 主播来源1:注册，2:BD邀请
     */
    sources?: {};
    /**
     * 主播类型
     */
    type?: {};
    /**
     * 微信号
     */
    weixinNo?: {};
    /**
     * bd组
     */
    bdGroupCode?: {};
    /**
     * bd
     */
    bdCode?: {};
    /**
     * 类目编号多个类目用;分开
     */
    category?: {};
    /**
     * 是否中介0-否1-是
     */
    isAgent?: {};
  };
  /**
   * 主播认证平台 ,LivePlatformParam
   */
  livePlatformParams: {
    /**
     * 业务编号
     */
    platformAccountId?: {};
    /**
     * 平台类型1:快手，2:抖音，3：淘宝
     */
    type?: {};
    /**
     * 平台账号
     */
    platformAccount: {};
    /**
     * 平台昵称
     */
    nickname: {};
    /**
     * 粉丝数量
     */
    fansNumber: {};
    /**
     * pid信息 ,AnchorPidParam
     */
    anchorPidParams: {
      /**
       * 平台id
       */
      platformAccountId?: {};
      /**
       * PID编号
       */
      adzoneNo?: {};
      /**
       * 直播平台：1:快手2:抖音3:小红书4:B站5:淘宝6:微博
       */
      platform?: {};
      /**
       * pid类型
       */
      pidType: {};
      /**
       * PID
       */
      pid: {};
    }[];
  }[];
  /**
   * 0,,前台编辑，1，后台编辑
   */
  editFrom?: {};
  /**
   * 联系人联系方式 ,AnchorContactUser
   */
  contactUsers?: {
    /**
     * 联系人
     */
    contactUser?: {};
    /**
     * 联系方式
     */
    contactPhone?: {};
  }[];
}
  /**
 * YResult<Void> :YResult
 */
export class IResid16980 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * Void
   */
  data?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16980, IResid16980['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/api/anchor/save_anchor_info',
        data: data
      }) 
  export default http