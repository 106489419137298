/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import Loading from '@components/Loading'

const Index = props => {
  const history = useHistory()
  useEffect(() => {
    const routeConf = props.routeConf
    if (routeConf && routeConf.length && routeConf[0].path) {
      history.push(routeConf[0].path)
    }
  }, [props])
  return <Loading/>
}

export default withRouter(Index)
