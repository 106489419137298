
    /**
    * 新增代播单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * List<WaitingLiveItemParam>
 */
export type IReqid18432 = {
  /**
   * spuid
   */
  itemNo: {};
  brandName?: {};
  itemName?: {};
  /**
   * 预估销量
   */
  estimatedSales?: {};
}[];
  /**
 * YResult :YResult
 */
export class IResid18432 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18432, IResid18432 > = (data?) =>  request({
        method: 'POST',
        url: '/api/WaitingLiveItem/saveWaitingLiveItem',
        data: data
      }) 
  export default http