
    /**
    * 修改密码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14545 {
  oldPwd: string;
  newPwd: string;
}
  export class IResid14545 {
  code: string;
  msg: string;
  success: boolean;
  data: {};
  exceptionMsg: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14545, IResid14545['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/auth/pwd/reset',
        data: data
      }) 
  export default http