
    /**
    * 样品列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14290 {
  account?: string | number;
  sampleSkuName?: string | number;
  sampleItemCategoryNo?: string | number;
  sampleStatus?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<SampleManageViewDTO>> :YResult
 */
export class IResid14290 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      sampleSkuName?: {};
      categoryName?: {};
      categoryNo?: {};
      anchorName?: {};
      anchorType?: {};
      mobileNumber?: {};
      liveTime?: {};
      actualReturnTime?: {};
      sampleStatus?: {};
      expectedDeliveryTime?: {};
      expectedReturnTime?: {};
      actualDeliveryTime?: {};
      returnTime?: {};
      /**
       * 样品编号
       */
      sampleSkuNo?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14290, IResid14290['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/sampleManage/pageListSample',
        params: data
      }) 
  export default http