
    /**
    * 获取上架活动
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13075 {}
  /**
 * YResult<List<ActivityDto>> :YResult
 */
export class IResid13075 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * ActivityDto
   */
  data?: {
    /**
     * 自增id
     */
    id?: {};
    /**
     * 活动编号
     */
    activityNo?: {};
    /**
     * 活动名称
     */
    activityName?: {};
    /**
     * 活动轮播图
     */
    bannerImage?: {};
    /**
     * 活动排序
     */
    sortNum?: {};
    /**
     * 活动状态：0下架1上架
     */
    status?: {};
    /**
     * 0否1是
     */
    isDeleted?: {};
    /**
     * 创建人
     */
    creator?: {};
    /**
     * 创建时间
     */
    createTime?: {};
    /**
     * 上架时间
     */
    shelfTime?: {};
    /**
     * 下架时间
     */
    offshelfTime?: {};
    /**
     * 修改人
     */
    updator?: {};
    /**
     * 修改时间
     */
    updateTime?: {};
  }[];
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13075, IResid13075['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/activity/listShelfActivities',
        params: data
      }) 
  export default http