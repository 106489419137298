
    /**
    * 账户解绑
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13350 {}
  /**
 * YResult :YResult
 */
export class IResid13350 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  export default (account: any,  data?: IReqid13350): AxiosPromise<IResid13350> => request({
        method: 'POST',
        url: '/admin/account/unBundling/'+account,
        data: data
      })