
    /**
    * 样品管理界面-取消样品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14460 {
  livePlanNo: string;
  sampleItemNo: string;
  sampleSkuNo: string;
}
  export class IResid14460 {
  code?: string;
  data?: null;
  success?: boolean;
  msg?: string;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14460, IResid14460['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleManage/sample/cancel',
        data: data
      }) 
  export default http