
    /**
    * 获取权限列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13380 {}
  export type IResid13380 = any
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13380, IResid13380 > = (data?) =>  request({
        method: 'GET',
        url: '/auth/user/permission',
        params: data
      }) 
  export default http