
    /**
    * 获取所有领域
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17195 {}
  export class IResid17195 {
  message?: string;
  code?: number;
  data?: {
    type: number;
    name: string;
  }[];
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17195, IResid17195['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/api/anchor/list_realm_info',
        params: data
      }) 
  export default http