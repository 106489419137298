
    /**
    * 合作方式下拉框
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18055 {}
  /**
 * YResult<YListData<DistributionConfigDto>> :YResult
 */
export class IResid18055 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      name?: {};
      value?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18055, IResid18055['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/get_brand_cooperation_type',
        params: data
      }) 
  export default http