
    /**
    * 区域查询
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17403 {
  /**
   * (Integer)
   */
  code?: string | number;
  /**
   * (Integer)
   */
  type?: string | number;
}
  /**
 * YResult<YListData<AreaListDTO>> :YResult
 */
export class IResid17403 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      name?: {};
      code?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17403, IResid17403['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/expressAddress/listArea',
        params: data
      }) 
  export default http