
    /**
    * 获取导入模板
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18180 {
  /**
   * (Integer)
   */
  templateType?: string | number;
}
  /**
 * YResult<String> :YResult
 */
export class IResid18180 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * data
   */
  data?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18180, IResid18180['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/importOrder/get_import_template',
        params: data
      }) 
  export default http