
    /**
    * 直播计划详情
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14400 {}
  /**
 * YResult<LivePlanListDto> :YResult
 */
export class IResid14400 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * LivePlanListDto
   */
  data?: {
    /**
     * 计划编号
     */
    livePlanNo?: {};
    /**
     * 直播时间
     */
    liveTime?: {};
    /**
     * 样品配送方式：1-自提2-快递3-无需样品
     */
    sampleDeliveryType?: {};
    /**
     * 计划来源：1-分销前台，2-小Y直播
     */
    planFromSource?: {};
    /**
     * 计划发货时间
     */
    expectedDeliveryTime?: {};
    /**
     * 计划到货时间
     */
    expectedGetTime?: {};
    /**
     * 计划归还时间
     */
    expectedReturnTime?: {};
    /**
     * 审核状态：1-待审批、2-审批不通过、3-待确认、4-待直播、5-直播结束、6-已取消
     */
    auditStatus?: {};
    /**
     * 用户备注
     */
    remark?: {};
    /**
     * 审核备注
     */
    auditRemark?: {};
    /**
     * 审核类型：1-新建审批2-变动后审批3-取消整体计划
     */
    auditType?: {};
    /**
     * 提交时间
     */
    createTime?: {};
    /**
     * 主播名称
     */
    accountName?: {};
    /**
     * 商品列表 ,LivePlanItemDto
     */
    itemList?: {
      /**
       * 商品编号
       */
      sampleItemNo?: {};
      /**
       * 商品名
       */
      itemName?: {};
      /**
       * 商品图片
       */
      itemImg?: {};
      /**
       * 品牌名称
       */
      brandName?: {};
      /**
       * 售价范围
       */
      salePriceMin?: {};
      /**
       * 售价范围
       */
      salePriceMax?: {};
      /**
       * 佣金范围
       */
      commissionRateMin?: {};
      commissionRateMax?: {};
      /**
       * 1-待审批、2-审批不通过、3-待确认、4-已确认、5-申请取消、6-已取消
       */
      auditStatus?: {};
      /**
       * 审核备注
       */
      auditRemark?: {};
      /**
       * 拒绝原因
       */
      rejectReason?: {};
      /**
       * 商品序号
       */
      itemIndex?: {};
      /**
       * 库存数量
       */
      itemStockSize?: {};
      /**
       * 审核变动原因：1-新增样品2-取消样品3-取消商品
       */
      auditChangeReason?: {};
      /**
       * 商品中心商品编号
       */
      outItemNo?: {};
      /**
       * Sku列表 ,LivePlanSkuDto
       */
      sampleSkuList?: {
        /**
         * 样品Sku编号
         */
        sampleSkuNo?: {};
        /**
         * 样品状态：1-待审批、2-审批不通过、3-待确认、4-待发货、5-待自提、6-待收货（后台返回已发货）、7-待归还、8-已归还、9-无需归还、10-申请取消、11-已取消、12已寄回、13-超时未归还、14-超时已归还
         */
        sampleStatus?: {};
        /**
         * 规格属性
         */
        skuProperty?: {};
        /**
         * 是否没有样品：0-否1-是
         */
        isNoSample?: {};
        /**
         * 样品库存数量
         */
        sampleStockSize?: {};
        /**
         * 外部Sku编号（关联商品中心）
         */
        outSkuNo?: {};
      }[];
      /**
       * 卖点信息（待直播透出） ,ItemSalePointDto
       */
      salePointInfo?: {
        /**
         * 卖点描述
         */
        salePoint?: {};
        /**
         * 卖点资源下载地址
         */
        downloadUrl?: {};
      };
      /**
       * 商品平台信息 ,ItemPlatformDto
       */
      platformInfoList?: {
        /**
         * 店铺类型：1-淘宝2-有赞3-快手4-抖音
         */
        shopType?: {};
        /**
         * 商品名
         */
        itemName?: {};
        /**
         * 店铺名
         */
        shopName?: {};
        /**
         * 商品链接
         */
        url?: {};
      }[];
    }[];
    /**
     * 统计信息 ,LivePlanStatisticsDto
     */
    statisticsInfo?: {
      /**
       * 商品种类(总数)
       */
      spuTypeTotalCount?: {};
      /**
       * 待审批商品数量
       */
      spuTypeToAuditCount?: {};
      /**
       * 已取消商品数量
       */
      spuTypeCancelCount?: {};
      /**
       * 样品数量（总数）
       */
      sampleTotalCount?: {};
      /**
       * 待审核样品数量
       */
      sampleToAuditCount?: {};
      /**
       * 待发货样品数量
       */
      sampleToDeliveryCount?: {};
      /**
       * 已取消样品数量
       */
      sampleCancelCount?: {};
      /**
       * 已发货样品数量
       */
      sampleDeliveredCount?: {};
    };
    /**
     * 样品间信息 ,LivePlanSampleRoomDto
     */
    sampleRoomInfo?: {
      /**
       * 样品间编号
       */
      sampleRoomNo?: {};
      /**
       * 样品间名称
       */
      sampleName?: {};
      /**
       * 地址
       */
      sampleAddress?: {};
      /**
       * 负责人
       */
      realName?: {};
      /**
       * 手机号
       */
      mobileNumber?: {};
    };
    /**
     * 收货地址信息 ,LivePlanAddressDto
     */
    addressInfo?: {
      /**
       * 地址编号
       */
      addressNo?: {};
      /**
       * 收货人
       */
      receiver?: {};
      /**
       * 手机号
       */
      phone?: {};
      /**
       * 省
       */
      province?: {};
      /**
       * 市
       */
      city?: {};
      /**
       * 区
       */
      district?: {};
      /**
       * 街道
       */
      street?: {};
      /**
       * 详细地址
       */
      detailAddress?: {};
    };
    /**
     * 更新时间
     */
    updateTime?: {};
  };
  exceptionMsg?: {};
}
  export default (livePlanNo: any,  data?: IReqid14400): AxiosPromise<IResid14400> => request({
        method: 'GET',
        url: '/front/live/plan/planDetail/'+livePlanNo,
        params: data
      })