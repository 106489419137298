
    /**
    * 账户登录2
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid12860 {
  /**
   * (String)
   */
  mobile?: string | number;
  /**
   * (String)
   */
  passWord?: string | number;
}
  /**
 * YResult<LoginView> :YResult
 */
export class IResid12860 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * LoginView
   */
  data?: {
    /**
     * token
     */
    token?: {};
    /**
     * 主播昵称
     */
    anchorName?: {};
    /**
     * 主播头像
     */
    anchorImage?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid12860, IResid12860['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/account/login',
        params: data
      }) 
  export default http