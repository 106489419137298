
    /**
    * 修改品牌方信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * BrandManageSaveParam :BrandManageSaveParam
 */
export class IReqid16905 {
  /**
   * 品牌编号
   */
  brandNo?: {};
  /**
   * 供应商编号
   */
  supplierNo?: {};
  /**
   * 可直播平台,多个以逗号分割,1快手，2抖音，3淘宝
   */
  liveType?: {};
  /**
   * 可设置模式，多个以逗号分割,1有赞CPS，2有赞分销，3抖音小店，4淘宝CPS，5快手分销
   */
  settableMode?: {};
  /**
   * 对外报的佣金,乘以100存整数
   */
  outCommission?: {};
  /**
   * 助播
   */
  assistedSeeding?: {};
  /**
   * 主播要求
   */
  anchorAsk?: {};
  /**
   * 备注
   */
  remarks?: {};
  /**
   * 直播场地列表 ,LivePlaceSaveParam
   */
  livePlaceList?: {
    /**
     * 场地类型
     */
    placeType?: {};
    /**
     * 场地名称
     */
    placeName?: {};
    /**
     * 省份province_id
     */
    provinceCode?: {};
    /**
     * 市id
     */
    cityCode?: {};
    /**
     * 区id
     */
    districtCode?: {};
    /**
     * 街道id
     */
    streetCode?: {};
    /**
     * 联系地址
     */
    detailAddress?: {};
  }[];
  updator?: {};
  /**
   * 是否分销：0否1是
   */
  isDistri?: {};
}
  /**
 * YResult :YResult
 */
export class IResid16905 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16905, IResid16905 > = (data?) =>  request({
        method: 'POST',
        url: '/brandManage/update',
        data: data
      }) 
  export default http