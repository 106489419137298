
    /**
    * 所有的一级类目
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13980 {}
  /**
 * YResult<List<CategoryStatisticsTreeNodeDto>> :YResult
 */
export class IResid13980 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * CategoryStatisticsTreeNodeDto
   */
  data?: {
    categoryNo?: {};
    categoryName?: {};
    parentNo?: {};
    hasChild?: {};
    isLeaf?: {};
    categoryNoPath?: {};
    total?: {};
  }[];
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13980, IResid13980['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/category/all/firstLevel',
        params: data
      }) 
  export default http