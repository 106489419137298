
    /**
    * 分页查询导入订单
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17811 {
  /**
   * 主播编号
   */
  anchorNo?: string | number;
  /**
   * 是否有效，0.无效1.有效
   */
  isEffective?: string | number;
  /**
   * 合作模式
   */
  cooperationType?: string | number;
  /**
   * 平台店铺平台店铺90504630-遥望严选有攒点1961399263-奥洛菲快手小店
   */
  platformShop?: string | number;
  /**
   * 导入开始时间
   */
  importStartTime?: string | number;
  /**
   * 导入结束时间
   */
  importEndTime?: string | number;
  /**
   * 直播开始时间
   */
  liveStartTime?: string | number;
  /**
   * 直播结束时间
   */
  liveEndTime?: string | number;
  /**
   * 订单类型1-cps2-一件代发3-自有店铺
   */
  orderType?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<ImportOrderDto>> :YResult
 */
export class IResid17811 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 业务编号
       */
      uniqueNo?: {};
      /**
       * 主播账号
       */
      anchorNo?: {};
      /**
       * 主播昵称
       */
      anchorName?: {};
      /**
       * 合作方式
       */
      cooperationType?: {};
      /**
       * 平台店铺平台店铺90504630-遥望严选有攒点1961399263-奥洛菲快手小店
       */
      platformShop?: {};
      /**
       * 直播时间
       */
      liveDate?: {};
      /**
       * 订单编号
       */
      orderNo?: {};
      /**
       * 订单状态
       */
      orderStatus?: {};
      /**
       * 支付时间
       */
      payTime?: {};
      /**
       * 支付金额
       */
      payMoney?: {};
      /**
       * 商品名称
       */
      itemName?: {};
      /**
       * 订单退款状态
       */
      refundStatus?: {};
      /**
       * 线上佣金率
       */
      onlineCommissionRate?: {};
      /**
       * 线上佣金
       */
      onlineCommission?: {};
      /**
       * 线下佣金率
       */
      offlineCommissionRate?: {};
      /**
       * 线下佣金
       */
      offlineCommission?: {};
      /**
       * 利润
       */
      profit?: {};
      /**
       * 成交数量
       */
      dealNumber?: {};
      /**
       * 商品成本
       */
      itemCost?: {};
      /**
       * 运费成本
       */
      shippingCost?: {};
      /**
       * 平台成本
       */
      platformCost?: {};
      /**
       * 订单类型1-cps2-一件代发3-自有店铺
       */
      orderType?: {};
      /**
       * 创建时间
       */
      createdAt?: {};
      /**
       * 店铺名称
       */
      platformShopName?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17811, IResid17811['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/importOrder/page_list',
        params: data
      }) 
  export default http