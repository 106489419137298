
    /**
    * 确认收货
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14305 {
  /**
   * 直播计划编号
   */
  ' livePlanNo': string;
  /**
   * 这个包裹下的sku编号
   */
  sampleSkuNos: string[];
}
  export class IResid14305 {
  code?: string;
  message?: string;
  data?: null;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14305, IResid14305['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleLogisticsRecord/confirm',
        data: data
      }) 
  export default http