
    /**
    * 前台用户修改密码
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13215 {
  /**
   * 老密码
   */
  oldPwd: string;
  /**
   * 新密码
   */
  newPwd: string;
}
  export class IResid13215 {
  code?: number;
  data?: string;
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13215, IResid13215['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/account/updatePassWord',
        data: data
      }) 
  export default http