
    /**
    * 通过手机号查询账号信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17216 {}
  /**
 * YResult<AccountDto> :YResult
 */
export class IResid17216 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * AccountDto
   */
  data?: {
    /**
     * 账号
     */
    account?: {};
    /**
     * 用户手机号
     */
    mobileNumber?: {};
    /**
     * 主播昵称
     */
    anchorName?: {};
    /**
     * 主播头像
     */
    anchorImage?: {};
    /**
     * 主播类型
     */
    anchorType?: {};
    /**
     * 账号状态0禁用1启用
     */
    accountStatus?: {};
    /**
     * 绑定状态0未绑定1已绑定
     */
    bindStatus?: {};
    token?: {};
    /**
     * 创建时间
     */
    createdAt?: {};
    /**
     * 更新时间
     */
    updatedAt?: {};
    /**
     * 是否删除，0.正常1.已删除
     */
    isDeleted?: {};
    /**
     * 绑定主播账号
     */
    bindAccount?: {};
    /**
     * 绑定主播名称
     */
    bindName?: {};
    /**
     * 是否允许借样1-是允许，0-不允许
     */
    enableGetSample?: {};
    /**
     * 账号类型0子账号1主账号
     */
    accountType?: {};
  };
  exceptionMsg?: {};
}
  export default (phone: any,  data?: IReqid17216): AxiosPromise<IResid17216> => request({
        method: 'GET',
        url: '/admin/account/get_account_by_phone/'+phone,
        params: data
      })