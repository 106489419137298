
    /**
    * 查看进度
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14490 {
  /**
   * 直播计划编号
   */
  livePlanNo: string;
}
  export class IResid14490 {
  code?: number;
  messsage?: string;
  data?: {
    list?: {
      expressCompanyName?: string;
      expressNo?: string;
      pkgTotal?: string;
      receiverPhone?: string;
      receiverName?: string;
      expectArriveTime?: string;
      receiverAddress?: string;
      /**
       * 自提人
       */
      selfPickUpPerson?: string;
      /**
       * 自提人联系方式
       */
      selfPickUpPhone?: string;
      /**
       * 自提地址
       */
      selfPickAddress?: string;
      submitPlanTime?: string;
      /**
       * 拿样联系人
       */
      selfPickRelationPerson?: string;
      /**
       * 拿样联系人电话
       */
      selfPickRelationPhone?: string;
      confirmPlanTime?: string;
      wsDeliveryTime?: string;
      confirmDeliveryTime?: string;
      itemDetail?: {
        itemName?: string;
        skuPropertyName?: string;
      }[];
      logisticsType?: string;
      /**
       * 1:提交计划、2:确认计划、3:仓库发货 4:确认收货
       */
      logisticsStatus?: string;
      liveTime?: string;
    }[];
  };
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14490, IResid14490['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/sampleLogisticsRecord',
        data: data
      }) 
  export default http