import moment from 'moment'
import Big from 'big.js'
import EnvConfig from '@config/config'
// export const GetKeyValueToOptions = (data: {}) => {
//   return Object.keys(data).map(key => ({
//     value: key,
//     label: data[key],
//   }))
// }
// @ts-ignore
export function GetKeyValueToOptions(data: {}, fn?: Function = String) {
  const _data = JSON.parse(JSON.stringify(data))
  return Object.keys(_data).map(key => ({
    value: fn(key),
    label: _data[key],
  }))
}

/**
 * 获取字节长度
 * @param str // 需要获取字节长度的字符串
 * 中文+2 英文+1
 */
export const getBiteLen = (str: string) => {
  if (!str) {
    return 0
  }
  let len = 0
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
      len += 2
    } else {
      len += 1
    }
  }
  return len
}

/**
 * 時間moment轉string
 */
export const formatTime = (
  date: object,
  format = 'YYYY-MM-DD HH:mm:ss'
): string => {
  if (!date) return ''
  return moment(date).format(format)
}

/**
 * 价格分到元转化
 * */
export const formatPrice = (price: string | number, num: number = 2) => {
  return price && !isNaN(Number(price))
    ? Big(Number(price))
      .div(Big(100))
      .toFixed(num)
    : 0
}

/**
*   获取链接参数
* */

export const getQueryVariable = (variable: string) => {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  // return false;
}

export const addQueries = (params) => {
    const arr = []
    Object.keys(params).forEach(key => {
      if (params[key] !== '' && params[key] !== null&& params[key] !== undefined) {
        arr.push(`${key}=${params[key]}`)
      }
    })
    return arr.join('&')
  }

// 导出excel
export const exportExcel = (url, params = {}, noPre) => {
    const data = Object.assign({}, params, {
      token: localStorage.getItem('YW_RETAIL_TOKEN')
    })
    const url1 = `${noPre ? '' : EnvConfig.API_BASE_URL}${url}?${addQueries(data)}`
    console.log(`导出excel：${url1}`)
    // window.open(url1)
    const id = `alink-${new Date().getTime()}`
    const aDom = document.createElement('a')
    aDom.setAttribute('href', url1)
    aDom.setAttribute('target', '_blank')
    aDom.setAttribute('id', id)
    if (!document.getElementById(id)) {
      document.body.appendChild(aDom)
    }
    // 模拟点击
    aDom.click()
    // 删除这个假dom
    setTimeout(() => {
      document.body.removeChild(aDom)
    }, 0)
  }