
    /**
    * 保存样品间信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * SampleRoomParam :SampleRoomParam
 */
export class IReqid17414 {
  sampleRoomNo?: {};
  /**
   * 样品间名称
   */
  sampleName?: {};
  /**
   * 负责人姓名
   */
  realName?: {};
  /**
   * 负责人联系方式
   */
  mobileNumber?: {};
  sampleAddress?: {};
  /**
   * 0正常
   */
  status?: {};
  /**
   * 分页
   */
  index?: {};
  size?: {};
}
  /**
 * YResult :YResult
 */
export class IResid17414 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17414, IResid17414 > = (data?) =>  request({
        method: 'POST',
        url: '/admin/sampleRoom/savaSampleRoom',
        data: data
      }) 
  export default http