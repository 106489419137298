import processEnv from './_env'
import ENV from './env'

const NODE_ENV            = processEnv.env as 'development' | 'production';
const APP_ENV             = processEnv.envType as 'mock' | 'debug' | 'test' | 'prod';
const API_BASE_URL        = ENV[processEnv.envType].API_BASE_URL as string;
const INTERNAL_CLIENT_URL = ENV[processEnv.envType].INTERNAL_CLIENT_URL as string;
const RETAIL_CLIENT_URL = ENV[processEnv.envType].RETAIL_CLIENT_URL as string;
const YW_PLATFORM_URL       = ENV[processEnv.envType].YW_PLATFORM_URL as string;

export default {
  NODE_ENV,
  APP_ENV,
  API_BASE_URL,
  INTERNAL_CLIENT_URL,
  RETAIL_CLIENT_URL,
  YW_PLATFORM_URL
}
