
    /**
    * 通过主播编号获取排期列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17215 {
  /**
   * 主播（昵称手机号微信）
   */
  anchorNo?: string | number;
  /**
   * bd组
   */
  bdGroupNo?: string | number;
  /**
   * 计划状态
   */
  planState?: string | number;
  /**
   * 起始时间
   */
  startTime?: string | number;
  /**
   * 分销人员
   */
  bdCode?: string | number;
  /**
   * 结束时间
   */
  endTime?: string | number;
  /**
   * 当前页
   */
  pageIndex?: string | number;
  /**
   * 当前页大小
   */
  pageSize?: string | number;
}
  /**
 * YResult<YListData<AnchorScheduleDTO>> :YResult
 */
export class IResid17215 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 主键
       */
      id?: {};
      /**
       * 主播编号
       */
      anchorNo?: {};
      /**
       * 主播昵称
       */
      nickname?: {};
      /**
       * 直播时间
       */
      liveTime?: {};
      /**
       * 计划状态
       */
      planState?: {};
      /**
       * bd组编码
       */
      bdGroupNo?: {};
      /**
       * bd组名
       */
      bdGroupName?: {};
      /**
       * 更新时间
       */
      updateTime?: {};
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
    hasNext?: {};
    pageSize?: {};
    pageIndex?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17215, IResid17215['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/schedules/page',
        params: data
      }) 
  export default http