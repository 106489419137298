import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'antd'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Retail = props => {
  const history = useHistory()
  const createBreadcrumbs = routeConf => {
    let breadcrumbs = []
    const loopCreate = list => {
      list &&
        list.some(item => {
          if (
            history.location.pathname.indexOf(item.path) > -1 &&
            item.path !== '/'
          ) {
            // const levelCount = item.path.slice('/').length
            // console.log('levelCount:', levelCount)
            breadcrumbs.push({ path: item.path, title: item.meta.title })
            if (item.children) {
              loopCreate(item.children)
            }
            return
          }
        })
    }
    loopCreate(routeConf)

    // console.log('breadcrumbs:', breadcrumbs)
    return breadcrumbs
  }
  const [breadcrumbs, setbreadcrumbs] = useState([])
  useEffect(() => {
    const breadcrumbs = createBreadcrumbs(props.routeConf)
    setbreadcrumbs(breadcrumbs)
  }, [props])

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">首页</Link>
      </Breadcrumb.Item>
      {breadcrumbs.map((item, index) => {
        return (
          <Breadcrumb.Item key={item.path}>
            {index === 0 ? (
              item.title
            ) : (
              <Link to={item.path}>{item.title}</Link>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

export default Retail
