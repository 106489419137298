
    /**
    * 前台选品中心查询商品中心列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItemQueryParam :DistributionItemQueryParam
 */
export class IReqid16695 {
  itemName?: {};
  categoryName?: {};
  minCommissionRate?: {};
  maxCommissionRate?: {};
  /**
   * Integer
   */
  promotionPlatform?: {}[];
  stockThresholdSize?: {};
  hasSample?: {};
  activityNo?: {};
  index: {};
  size: {};
}
  /**
 * Response :Response
 */
export class IResid16695 {
  message?: {};
  code?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16695, IResid16695 > = (data?) =>  request({
        method: 'POST',
        url: '/item/distribution/list',
        data: data
      }) 
  export default http