
    /**
    * 获取样品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid14275 {}
  /**
 * YResult<SampleSettingViewDTO> :YResult
 */
export class IResid14275 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * SampleSettingViewDTO
   */
  data?: {
    /**
     * 内部主播直播前x条到样
     */
    beforeZhiboIn?: {};
    /**
     * 内部主播直播后x天还样
     */
    afterZhiboIn?: {};
    /**
     * 内部主播发货天数
     */
    deliveryDaysIn?: {};
    /**
     * 内部主播归还天数
     */
    returnDaysIn?: {};
    /**
     * 外部主播直播前x条到样
     */
    beforeZhiboOut?: {};
    /**
     * 外部主播直播后x天还样
     */
    afterZhiboOut?: {};
    /**
     * 外部主播发货天数
     */
    deliveryDaysOut?: {};
    /**
     * 外部主播归还天数
     */
    returnDaysOut?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid14275, IResid14275['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/sampleManage/latest',
        params: data
      }) 
  export default http