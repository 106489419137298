
    /**
    * 遥望星选1.0 后台分销商品列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItems4BeQueryParam :DistributionItems4BeQueryParam
 */
export class IReqid17406 {
  /**
   * 商品名称
   */
  itemName?: {};
  /**
   * 商品的上下架状态
   */
  status?: {};
  /**
   * 类目编号
   */
  categoryNo?: {};
  /**
   * 品牌编号
   */
  brandNo?: {};
  /**
   * 推广平台
   */
  promotionPlatform?: {};
  index: {};
  size: {};
}
  /**
 * YResult<YListData<DistributionBeItemInfoDto>> :YResult
 */
export class IResid17406 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      itemUniqueNo?: {};
      itemName?: {};
      skuImage?: {};
      brandName?: {};
      categoryName?: {};
      skuPropertyName?: {};
      supplyPrice?: {};
      skuSize?: {};
      sampleSize?: {};
      status?: {};
      sellPointUrl?: {};
      isOnly?: {};
      /**
       * DistributionBeItemListViewDto
       */
      platformInfoList?: {
        shopType?: {};
        zhiboPrice?: {};
        dailyPrice?: {};
        commissionRate?: {};
      }[];
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17406, IResid17406['data'] > = (data?) =>  request({
        method: 'POST',
        url: '/admin/item/distribution/be/v2/list',
        data: data
      }) 
  export default http