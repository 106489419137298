
    /**
    * 前台查询活动商品
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  /**
 * DistributionItemQueryParam :DistributionItemQueryParam
 */
export class IReqid13155 {
  itemName?: {};
  categoryName?: {};
  minCommissionRate?: {};
  maxCommissionRate?: {};
  /**
   * Integer
   */
  promotionPlatform?: {}[];
  stockThresholdSize?: {};
  hasSample?: {};
  activityNo?: {};
  pageSize?: {};
  pageIndex?: {};
  rowId?: {};
  jumpType?: {};
  searchCount?: boolean;
  /**
   * OrderByColumn
   */
  orderObjects?: {
    orderByColumn?: {};
    orderByDesc?: boolean;
  }[];
}
  /**
 * YResult :YResult
 */
export class IResid13155 {
  code?: {};
  msg?: {};
  success?: {};
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13155, IResid13155 > = (data?) =>  request({
        method: 'POST',
        url: '/item/activity/list',
        data: data
      }) 
  export default http