/**
 * 缓存应用级别数据库
 */
class Cache {
  token: string = ''
  user = { userName: '', anchorName: '', anchorImage: '', username: '' }
  platform = ''

  constructor() {
    this.init()
  }

  /**
   * 同步更新变量和缓存
   */
  set(key: string, value: any) {
    const keyName = `YW_RETAIL_${key.toUpperCase()}`
    localStorage.setItem(keyName, typeof value === 'object' ? JSON.stringify(value) : value)
    this[key] = value
  }

  /**
   * 获取token
   */
  get(key: keyof Cache) {
    const keyName = `YW_RETAIL_${key.toUpperCase()}`
    return localStorage.getItem(keyName)
  }

  remove(key: keyof Cache) {
    const keyName = `YW_RETAIL_${key.toUpperCase()}`
    console.log('即将删除缓存', key, this[key])
    localStorage.removeItem(keyName)
    this[key] = null
  }

  clear() {
    console.log('即将清空缓存', Object.keys(this))
    Object.keys(this).forEach(key => {
      if (key === 'platform') return
      this[key] = null
      const keyName = `YW_RETAIL_${key.toUpperCase()}`
      try {
        localStorage.removeItem(keyName)
      } catch (e) {
        console.log(`无此${key}`)
      }
    })
  }
  /**
   * 初始化缓存到变量上
   */
  private init() {
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        const keyName = `YW_RETAIL_${key.toUpperCase()}`
        this[key] = this.translateData(localStorage.getItem(keyName)) || null
      }
    }
  }

  private translateData(data: string) {
    try {
      return JSON.parse(data)
    } catch (e) {
      return data
    }
  }
}

export const cache = new Cache()
