
    /**
    * 直播场地列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid16760 {
  supplierNo?: string | number;
  brandNo?: string | number;
}
  /**
 * YResult<YListData<LivePlaceDTO>> :YResult
 */
export class IResid16760 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 自增id
       */
      id?: {};
      /**
       * 场地类型1品牌方总部2蔚蓝品牌馆3专柜4其他
       */
      placeType?: {};
      /**
       * 场地编号
       */
      placeNo?: {};
      /**
       * 品牌编号
       */
      brandNo?: {};
      /**
       * 场地名称
       */
      placeName?: {};
      /**
       * 省份province_id
       */
      provinceCode?: {};
      /**
       * 省份名称
       */
      provinceCodeName?: {};
      /**
       * 市id
       */
      cityCode?: {};
      /**
       * 市id名称
       */
      cityCodeName?: {};
      /**
       * 区id
       */
      districtCode?: {};
      /**
       * 区id名称
       */
      districtCodeName?: {};
      /**
       * 街道id
       */
      streetCode?: {};
      /**
       * 街道id名称
       */
      streetCodeName?: {};
      /**
       * 联系地址
       */
      detailAddress?: {};
      /**
       * 创建时间
       */
      createTime?: {};
      /**
       * 修改时间
       */
      updateTime?: {};
      /**
       * 供应商编号
       */
      supplierNo?: {};
    }[];
    totalNumber?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid16760, IResid16760['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/livePlace/list',
        params: data
      }) 
  export default http