
    /**
    * 收发样品列表
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid18647 {
  /**
   * 主播编号
   */
  anchorNo?: string | number;
  /**
   * 样品状态
   */
  sampleStatus?: string | number;
  /**
   * 预计到样开始时间
   */
  expectedGetTimeStart?: string | number;
  /**
   * 预计到样结束时间
   */
  expectedGetTimeEnd?: string | number;
  pageSize?: string | number;
  pageIndex?: string | number;
  rowId?: string | number;
  jumpType?: string | number;
  searchCount?: string | number;
  orderObjects?: string | number;
}
  /**
 * YResult<YListData<ReceiptOrSendSampleDto>> :YResult
 */
export class IResid18647 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * YListData
   */
  data?: {
    /**
     * T
     */
    list?: {
      /**
       * 计划样品唯一标识
       */
      sampleSkuNo?: {};
      /**
       * 发货时间
       */
      actualDeliveryTime?: {};
      /**
       * 预计到样时间
       */
      expectedGetTime?: {};
      /**
       * 直播时间
       */
      liveDate?: {};
      /**
       * 寄回时间
       */
      returnTime?: {};
      /**
       * 商品名称
       */
      ItemName?: {};
      /**
       * 主图
       */
      mainImag?: {};
      /**
       * 主播名称
       */
      anchorName?: {};
      /**
       * 样品状态
       */
      sampleStatus?: {};
      /**
       * 分销人员名称
       */
      bdName?: {};
      /**
       * 收件人信息 ,LivePlanAddressDto
       */
      livePlanAddressDto?: {
        /**
         * 地址编号
         */
        addressNo?: {};
        /**
         * 收货人
         */
        receiver?: {};
        /**
         * 手机号
         */
        phone?: {};
        /**
         * 省
         */
        province?: {};
        /**
         * 市
         */
        city?: {};
        /**
         * 区
         */
        district?: {};
        /**
         * 街道
         */
        street?: {};
        /**
         * 详细地址
         */
        detailAddress?: {};
      };
    }[];
    totalNumber?: {};
    totalPage?: {};
    rowId?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid18647, IResid18647['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/admin/sampleManage/receiptOrSendSamplePageList',
        params: data
      }) 
  export default http