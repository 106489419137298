
    /**
    * 查询供应商品牌详细信息
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid17677 {
  /**
   * 品牌编号
   */
  brandNo: string | number;
  /**
   * 时间类型
   */
  timeType: string | number;
  /**
   * 时间
   */
  time: string | number;
}
  /**
 * YResult<SupplierBrandDetailDto> :YResult
 */
export class IResid17677 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * SupplierBrandDetailDto
   */
  data?: {
    /**
     * 品牌logo
     */
    brandLogo?: {};
    /**
     * 是否独家0-否，1-是
     */
    isOnly?: {};
    /**
     * 是否品牌馆
     */
    isBrandRoom?: {};
    /**
     * 佣金最大值
     */
    commissionMax?: {};
    /**
     * 佣金最小
     */
    commissionMin?: {};
    /**
     * 常备货值
     */
    goodsValue?: {};
    /**
     * spu数量
     */
    spuNum?: {};
    /**
     * 已提报spu数量
     */
    submitSpuNum?: {};
    /**
     * 准备时间
     */
    readyTime?: {};
    /**
     * 直播地点 ,String
     */
    livePlaces?: {}[];
    /**
     * 素材拍摄地点 ,String
     */
    filmingPlaces?: {}[];
    /**
     * 合作方式
     */
    cooperationTypes?: {};
    /**
     * 提提包合作方式
     */
    submitCooperationTypes?: {};
    /**
     * 品牌名称
     */
    brandName?: {};
    /**
     * 已排场次
     */
    liveCount?: {};
    /**
     * 直播间编号
     */
    liveRoomNo?: {};
    /**
     * 直播间名称
     */
    liveRoomName?: {};
  };
  exceptionMsg?: {};
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid17677, IResid17677['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/schedulingPlan/get_supplier_brand_detail',
        params: data
      }) 
  export default http