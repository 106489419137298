import { AnyAction } from "redux";

export interface IRoleState {
  name: string;
  role: string;
  avatar: string;
}

const initRoleInfo: any = {
  role: {}
};

export default function role(
  state = initRoleInfo,
  action: AnyAction
): any {
  switch (action.type) {
    case 'SET_ROLE':
      return {
        role: {...action.role}
      }
    default:
      return state;
  }
}
