
    /**
    * 获取区域联动地址
    **/
      
  import request, {AxiosPromise} from '@utils/request'
  export class IReqid13250 {
  /**
   * 区域编码
   */
  code?: string | number;
  /**
   * 区域类型
   */
  type: string | number;
}
  export class IResid13250 {
  code?: number;
  data?: {
    code: number;
    name: string;
  }[];
  message?: string;
  success?: boolean;
}
  type Serve<T, G> = (data?: T) => AxiosPromise<G>
  const http: Serve<IReqid13250, IResid13250['data'] > = (data?) =>  request({
        method: 'GET',
        url: '/expressAddress/listArea',
        params: data
      }) 
  export default http